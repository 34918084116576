import React from 'react';
import {NavLink} from "react-router-dom";
//ani1 btnNavUp bt7cj fs2
const BtnNav = ({lTo,text,noact}) => {
    if(!noact) {
    return (
        <NavLink to={lTo} className={({isActive}) =>
                      isActive
                        ? "ani1 btnNavUp fs2 bt5hact"
                        : "ani1 btnNavUp bt7cj fs2"
                    }        
                     >
            {text}
        </NavLink>
    );
    } else {
        return (
            <div to={lTo} className = 'ani1 btnNavUp fs2 nab6h'>
                {text}
            </div>
        );
    }
};

export default BtnNav;