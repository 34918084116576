import React, {useState,useEffect,useRef} from 'react';
import {Transition} from 'react-transition-group';
import Loader from './loader';
import {paths0} from '../../../../API/paths0';

const PlImg = (p) => {
    const ref=useRef(null);
    const [url,setUrl]=useState('');
    const [preloader, setPreloader]=useState(true);
    var urlUp = '';
        urlUp=paths0.local+'img/nft/'+p.coll+'/'+p.url;
    useEffect(()=>{
        const f = async()=> {
        try {
            const res = await fetch(urlUp)
            .then(response => response.blob())
            .then((image) => {
                setUrl(URL.createObjectURL(image));
            });
          } catch {
            console.error('Failed')
          }
        }
        f();
    },[]);
    return (
        <div className='pl_nft1'>
        {preloader && <Loader />}
        <Transition nodeRef={ref} unmountOnExit={true} in={url!=''} timeout={100} onEnter={() => setPreloader(false)}>
            {state => (
                <img className={`ani1 imgNftL op1-${state}`} ref={ref} src={url} />
            )}
        </Transition>
        </div>
    );  
};

export default PlImg;