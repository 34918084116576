import air_1 from '../images/collections/iconTypeA_1.webp';
import air_2 from '../images/airdrops/airXi1.webp';
import air_3 from '../images/collections/iconTypeB_1.webp';
import air_4 from '../images/collections/iconTypeC_1.webp';
import air_5 from '../images/collections/iconTypeD_1.webp';
import air_6 from '../images/collections/iconTypeE_1.webp';
import air_7 from '../images/collections/iconTypeE_2.webp';
import air_8 from '../images/collections/iconTypeE_3.webp';
import air_9 from '../images/collections/iconTypeF_1.webp';

import abWea from '../images/collections/abWea.png';
import abUpg from '../images/collections/abUpg.png';
import abCar from '../images/collections/abCar.webp';

export const Airinf = {
1: {
    "img":air_1,
    "air":"NFT",
    "act": "Coming Soon",
    "stat":"Coming Soon"
},
2: {
    "img":air_2,
    "air":"Coin $XIVI",
    "act": "For first Xivion Farm NFT Collections holder (Legendary, Weapons Hunter, Upgrade Hunter)",
    "stat":"Coming Soon"
},
3: {
    "img":air_3,
    "air":"NFT",
    "act": "Coming Soon",
    "stat":"Coming Soon"
},
4: {
    "img":air_7,
    "air":"NFT",
    "act": "Coming Soon",
    "stat":"Coming Soon"
},
5: {
    "img":air_5,
    "air":"NFT",
    "act": "Coming Soon",
    "stat":"Coming Soon"
},
6: {
    "img":air_6,
    "air":"NFT",
    "act": "Coming Soon",
    "stat":"Coming Soon"
},
7: {
    "img":air_4,
    "air":"NFT",
    "act": "Coming Soon",
    "stat":"Coming Soon"
},
8: {
    "img":air_8,
    "air":"NFT",
    "act": "Coming Soon",
    "stat":"Coming Soon"
},
9: {
    "img":air_9,
    "air":"NFT",
    "act": "Coming Soon",
    "stat":"Coming Soon"
}
}

export const AbImg = {
    0:abCar,
    1: abWea,
    2:abUpg
}