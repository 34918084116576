import React,{useState} from 'react';

const BtnSelect2 = ({name,val}) => {
return (
    <button className='btnChoN pad5 br'>
        <div className='co3 fs2'>{name}</div>
        <div className='co4 fs1'>{val}</div>
    </button>
);
};

export default BtnSelect2;