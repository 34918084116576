import React,{useEffect,useState,useRef} from 'react';
//import * as THREE from 'three'; 
import {Transition} from 'react-transition-group';
import Loader from '../elem/itemnft/loader';
import AboutNft from '../../../API/aboutNft';
import NavBar1 from './NavBar1';
import Img3D from './Img3D'
import InfOf from './infOf';
import {stInfo} from '../../../API/startinfo';
import { useTranslation } from 'react-i18next';

import Viewer from './Viewer';
import QRCode from "react-qr-code";

const AboutMnft = ({nft,close}) => {
    const { t } = useTranslation();
    const [st, setSt] = useState('');
    const [isLoad,setIsLoad] = useState(false);
    const refImg = 'imgop63';
    const [chose,setChose] = useState(1);
    const [imO,setImO] = useState(true);
    const [nftInf,setNftInf] = useState('');

useEffect(()=>{
    const asy = async()=> {
        try {
            const res = await AboutNft.show(nft[0],nft[1]);
            //console.log(res.data.nft);
            res.data.nft ? setNftInf(res.data.nft) : close('')
        }
        catch(e) {
            console.log('err');
        }
        finally {
            setIsLoad(true);
        }
        }
    asy();
},[]);

const _change = (c)=> {
    var imgH = document.getElementById(refImg);
    var canv12= document.getElementById('canDi');
    var qr_ar=document.getElementById('qr_ar');
    if(c===1&&chose!==c) {
        setChose(1);
        setImO(true);
        if(imgH.classList.contains('op0')) {
            imgH.classList.remove('op0');
        }
        if(!canv12.classList.contains('op0')) {
            canv12.classList.add('op0');
        }
        if(!qr_ar.classList.contains('op0')) {
            qr_ar.classList.add('op0');
        }
    }
    if(c===2&&chose!==c) {
        setChose(2);
        setImO(false);
        if(!imgH.classList.contains('op0')) {
            imgH.classList.add('op0');
        }
        if(canv12.classList.contains('op0')) {
            canv12.classList.remove('op0');
        }
        if(qr_ar.classList.contains('op0')) {
            qr_ar.classList.add('op0');
        }
    }
    if(c===3&&chose!==c) {
        setChose(3);
        if(!imgH.classList.contains('op0')) {
            imgH.classList.add('op0');
        }
        if(!canv12.classList.contains('op0')) {
            canv12.classList.add('op0');
        }
        if(qr_ar.classList.contains('op0')) {
            qr_ar.classList.remove('op0');
        }
    }
    /*(c===1||chose!=c) && setChose(2);
    (c===1||chose!=c) && setChose(3);
    (c===2||c===3) && setChose('');*/
}

const _copy = (id) => {
    document.getElementById(id).select();
    document.execCommand('copy');
}
function _delObj(obj) {
    function d_dis(ch) {
        ch.removeFromParent();
    if (ch.geometry) {
        ch.geometry.dispose();
        ch.geometry = null;
    }     
    if (ch.material) {
        if (ch.material.map) {
            ch.material.map.dispose();
            ch.material.map = null;
        }
        if (ch.material.lightMap) {
            ch.material.lightMap.dispose();
            ch.material.lightMap = null;
        }
        if (ch.material.bumpMap) {
            ch.material.bumpMap.dispose();
             ch.material.bumpMap = null;
        }
        if (ch.material.normalMap) {
            ch.material.normalMap.dispose();
            ch.material.normalMap = null;
        }
        if (ch.material.specularMap) {
            ch.material.specularMap.dispose();
            ch.material.specularMap = null;
        }
        if (ch.material.specularColorMap) {
            ch.material.specularColorMap.dispose();
            ch.material.specularColorMap = null;
        }
        if (ch.material.envMap) {
            ch.material.envMap.dispose();
            ch.material.envMap = null;
        }
        if (ch.material.emissiveMap) {
            ch.material.emissiveMap.dispose();
            ch.material.emissiveMap = null;
        }
        if (ch.material.metalnessMap) {
            ch.material.metalnessMap.dispose();
            ch.material.metalnessMap = null;
        }
        if (ch.material.roughnessMap) {
            ch.material.roughnessMap.dispose();
            ch.material.roughnessMap = null;
        }    
        ch.material.dispose();
        ch.material = null;
    }
    }    
    function d_sechCh(ch) {
        for (let i=ch.children.length-1; i>=0 ;i--) {
            if (ch.children[i].name != 'spark3') {
                d_sechCh(ch.children[i]);
                d_dis(ch.children[i]); }
            }
    }
        /*dracoLoader.dispose();
        dracoLoader = null;
        loaderGLTF = null;
        managerM = null;
        Objs[pAddObj] = null;*/
        d_sechCh(obj);
        //gltfLoader.dispose();
        //setGltfLoader(null);
        //draco.dispose();
        //setDraco(null);
        //console.log(obj);
}

const id1 = 'f3ncm';
const id2 = 'hdw4v';

const _xClo = (id) => {
    close('');
}

    if(isLoad===true) {
        const nft_id = nftInf.nft_id.substring(0, 10)+'....'+nftInf.nft_id.substring(nftInf.nft_id.length - 7);
        const did = stInfo[3][nftInf.col_id].did;
        const did_dot = did.substring(0, 13)+'....'+did.substring(did.length - 7);
        const parts = nftInf.parts;
        //console.log(nftInf);   
    return (
    <div className="modalNft">
        <button className="ani1 close pa fs5 z6 bbf2" style={{right:'15px'}} onClick={()=>_xClo(1)}>X</button>
        <div className='cc pr br pad15 n1Di jcc'>
            <button className="ani1 close pa fs5 z6 gg9" style={{right:'15px'}} onClick={()=>_xClo(1)}>X</button>
                <div className='nInf'>
                <h2 className='h2 tac'>{nftInf.name}</h2>
                <div className='rap3'></div>
                    <div className='Dview pad5 pr'>
                    <NavBar1 change={_change} />
                        <div className='pr' style={{height:'100%'}}>
                        <Img3D id={refImg} url={nftInf.preview_img} coll={stInfo[3][nftInf.col_id]['coll_id']} cc='cc' css='dimg3d pa whF lt0' />
                        {parts && <Viewer st={st} setSt={setSt} parts = {parts} chose={chose} />}
                        <div className='dQr whF tac op0' id='qr_ar'>
                            <h3 className='h3g tac'>{t('ax58')}</h3>
                            <p className='p5h'>
                                {t('ax60')}
                            </p>
                            <div>
                                <QRCode
                                    bgColor={'#1b2c34'}
                                    fgColor={'#d3f1ff'}
                                    size={256}
                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                    value={`https://ar-room.xivion.io/?nft_id=`+nftInf.nft_id}
                                    viewBox={`0 0 256 256`}
                                />
                            </div>
                            <p className='p7j tac'>
                                {t('ax59')}<br></br>
                                <a href={`https://ar-room.xivion.io/?nft_id=`+nftInf.nft_id}>ar-room.xivion.io...</a>
                            </p>
                        </div>
                        </div>
                    </div>
                    <div className='fs2 dj74'>
                        <span className='co2'>NFT ID:</span> {nft_id} 
                        <input type="text" className="inp_o0" id={id1} defaultValue={nftInf.nft_id} />
                        <button onClick={()=>_copy(id1)} className='a52 ml15'><i className="ani1 fa fa-clone" aria-hidden="true"></i></button>
                        <a target="_blank" href = {`https://mintgarden.io/nfts/${nftInf.nft_id}`} className='a52 ml15' title={`${t('view_on')} MintGarden`}><i className="ani1 fa fa-external-link" aria-hidden="true"></i></a>
                    </div>
                    <div className='fs2 dj74'>
                        <span className='co2'>Creator:</span> {did_dot}
                        <input type="text" className="inp_o0" id={id2} defaultValue={did} />
                        <button onClick={()=>_copy(id2)} className='a52 ml15'><i className="ani1 fa fa-clone" aria-hidden="true"></i></button>
                        <a target="_blank" href = {`https://mintgarden.io/profile/${did}`} className='a52 ml15' title={`${t('view_on')} MintGarden`}><i className="ani1 fa fa-external-link" aria-hidden="true"></i></a>
                    </div>
                </div>
                <InfOf nftInf={nftInf} />
        </div>
    </div>    
    )} else {
        return (
            <div className="modalNft">
                <div className=''>
                    <Loader />
                </div>
            </div>
        )
    }
};

export default AboutMnft;