import React, {useState, useEffect,useRef} from 'react';
import i18n from 'i18next';
import { Transition } from 'react-transition-group';

const locales = {
    en:'English',
    ru:'Русский',
    cn:'中文'
};

const Langs = ({mob}) => {
    const idn = useRef(null);
    const [open,setOpen] = useState(false);
    function ChLang(l) {
        i18n.changeLanguage(l);
        setOpen(false);
    }
    useEffect(()=>{
        function _lang_ch(ee) { 
            let s1 = ee.composedPath().includes(document.getElementById("langDiv"));
            let s2 = ee.composedPath().includes(document.getElementById("lngbtn"));
                if (!s1 && !s2) {
                setOpen(false);
                document.removeEventListener('click', _lang_ch, false);
                }
        }
        if (open) {document.addEventListener('click', _lang_ch,false);}
    return () => {document.removeEventListener('click', _lang_ch,false);};
    }, [open]);

    return (
        <div className={mob===1 ? 'pofe3' : ''}>
            <button className={mob===1 ? 'fgr4 bto' : 'bto'} id = "lngbtn" onClick ={()=>setOpen(!open)}>
                {mob===1 && <div className='loc4'>{locales[i18n.resolvedLanguage]}</div>} <i className="ani1 i6l4 fs5 fa fa-globe" aria-hidden="true"></i>
            </button>
            <Transition nodeRef={idn} unmountOnExit={true} in={open} timeout={300} >
            {state => (
                <div ref={idn} className={`ani1 langFdiv lg1-${state}`} id ='langDiv'>
                    {Object.keys(locales).map((locale)=>(
                    <button key={'lgK_'+locale} className={i18n.resolvedLanguage === locale ? 'btnLang ani1 actLang' : 'btnLang ani1'} onClick={() => ChLang(locale)}>
                    {locales[locale]}
                    </button>
                    ))}
                </div>
            )}
            </Transition>
        </div>
    );
};

export default Langs;