import {createContext,useContext,useEffect,useLayoutEffect,useMemo,useRef,useState} from "react";

class MiniStore {
  #subscriptions = new Set();
  #state;
  constructor(initialState) {
    this.#state = initialState;
  }

  getState = () => {
    return this.#state;
  };

  update = (partialNewState) => {
      this.#state = { ...this.#state, ...partialNewState };
    //console.log(this.#state);
    this.#subscriptions.forEach((cb) => {
      cb();
    });
  };

  subscribe = (cb) => {
    //console.log(cb);
    this.#subscriptions.add(cb);
    return () => {
      this.#subscriptions.delete(cb);
    };
  };
}
  
function createOptimizedContext() {
    const Context = createContext(null);
  
    const Provider = ({
      initialState,
      children,
    }) => {
      const store = useMemo(() => new MiniStore(initialState), []);
      return <Context.Provider value={store}>{children}</Context.Provider>;
    };
  
    const useStore = () => {
      const store = useContext(Context);
      if (!store) {
        throw new Error("`UseStore` outside of `Provider`");
      }
      return store;
    };
  
    const useSerchCSelector = (selector) => {
      const store = useStore();
      const [state, setState] = useState(() => selector(store.getState()));
      const selectorRef = useRef(selector);
      const stateRef = useRef(state);
  
      useLayoutEffect(() => {
        selectorRef.current = selector;
        stateRef.current = state;
      });
  
      useEffect(() => {
        //console.log('---------------');
        return store.subscribe(() => {
          const state = selectorRef.current(store.getState());
  
          if (stateRef.current === state) {
            return;
          }
  
          setState(state);
        });
      }, [store]);
      return state;
};
  
  const useSerchCUpdate = () => {
      const store = useStore();
  
      return store.update;
  };
  return { Provider, useSerchCSelector, useSerchCUpdate };
}
  
  export const {
    Provider: SerchCProvider,
    useSerchCSelector,
    useSerchCUpdate,
  } = createOptimizedContext();