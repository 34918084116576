import React from 'react';

const BtnConn = () => {
    return (
        <button className = "cn btn2 fs2">
            Connect wallet
        </button>
    );
};

export default BtnConn;