import React, {useState,useEffect,useRef} from 'react';
import ItemNFT from '../ui/elem/itemnft/itemnft';
import ItemNftNft from '../ui/elem/itemnft/itemNftNft';
import MultiNFT from '../ui/elem/itemnft/MultiNFT.jsx';

import ListNftSale from '../../API/listNftSale.js';
import {useMaSelector} from  '../../context/marketCont';
import {useSerchCSelector,useSerchCUpdate} from '../../context/serchC.js';
import Loader from '../ui/elem/itemnft/loader.jsx';
import { useTranslation } from 'react-i18next';

const RightBar = (p) => {
    const { t } = useTranslation();
    const [items, setItems] = useState('');

    const rate = useMaSelector((state) => state.value); 
    const serche = useSerchCSelector((state) => state.value);

    const [oldSerch,setOldSerch] =useState('');
    var isLoad = JSON.stringify(oldSerch)===JSON.stringify(serche) ? true : false

    const observer = useRef();
    const observRef = useRef();
    const [page,setPage]= useState(1);
    const [nextItems,setNextItems]= useState(false);
    const [side,setSide] = useState(0);
    const [err1,setErr1] = useState(false);

    useEffect(()=>{
        const asy = async()=> {
        try {
            const list = await ListNftSale.show(1,serche.type,serche.of,serche.req,serche.atr);
            list.data.end == 1 ? setNextItems(false) : setNextItems(true);
            //console.log(list.data);
            //Object.keys(list.data.res).length < 30 ? setNextItems(false) : setNextItems(true)
            setItems(list.data.res); //30 pic
            setPage(list.data.page);
            setErr1(false);
            list.data.side_nft && setSide(list.data.side_nft);
        }
        catch(e) {
            console.log('err');
            setErr1(true);
        }
        finally {
            setOldSerch(serche);
        }
        }
    asy();
        
    },[serche]);


    useEffect(()=>{
        if(observer.current) observer.current.disconnect();
        if(isLoad===false || nextItems===false) return
            let callback = function(entries, observ) {
                if(entries[0].isIntersecting  ) {
                    const asy = async()=> {
                        try {
                            const list = await ListNftSale.show(page+1,serche.type,serche.of,serche.req,serche.atr);
                            list.data.end == 1 ? setNextItems(false) : setNextItems(true);
                            //Object.keys(list.data.res).length < 30 ? setNextItems(false) : setNextItems(true);
                                setItems([...items, ...list.data.res]); //90 pic
                                setPage(list.data.page);
                                setErr1(false);
                        }
                        catch(e) {
                            console.log('err');
                            setErr1(true);
                        }
                    }
                    asy();  
                }
            } 
            observer.current = new IntersectionObserver(callback);
            observer.current.observe(observRef.current)
    },[items]);

    if(isLoad) {
        if(items&&Object.keys(items).length>0) {
           
    return (
        <div className="bar2" >
                {
                    Object.keys(items).map((item,i)=>(
                        serche.type===1 ? <ItemNFT item={items[item]} type={serche.type} rate={rate} side_nft={side} key={items[item].id_nft}/> 
                        : serche.type===3 ? <MultiNFT item={items[item]} type={serche.type} key={`n8k7_${i}`}/>
                            : <ItemNftNft item={items[item]} type={serche.type} key={`n3k7_${i}`}/>
                        )          
                   )
                   
                }

            <div ref={observRef} className='end tac'>{!nextItems && '--- End ---'}</div>
        </div>
    )
            } else { return (
                <div className="bar2" id='barr6'>
                    <div className='fs4 pad15 co5'>-- {err1 ? t('err1') : t('nores')} --</div>
                </div>
            )
            }
    } else {
        return (
            <div className="bar2">
                <Loader />
            </div>
        )
    }
};

export default RightBar;