import React,{useState,useRef} from 'react';
import { useTranslation } from 'react-i18next';
import {CoinsStInfo} from '../../../../API/startinfo';
import SelectRow from './selectRow';
import SelectTab from './selectTab';



const ChoseDivCoin = ({chos, sele,close}) => {
    const { t } = useTranslation();

    return (
        <div className="whF">
            <div className="upD1 w100 pad15">
                <h3 className="h3">Coins</h3>
                    {Object.keys(CoinsStInfo).map((id)=>(
                        <button key={`coin_${id}`} className='ani1 df jcc btncoll pad5' onClick={()=>(sele(chos,id))}>
                            <div className='forimg1'><img src={CoinsStInfo[id].img} /></div>
                            <p className="p1">{CoinsStInfo[id].name}</p>
                        </button>
                    ))}
            </div>
            <div className="downD1 w100 df jcc">
                <button className="ani1 btnser btnC2 fs2 br" onClick={()=>close(false)}>{t('back')}</button>
            </div>
        </div>
    );
};

export default ChoseDivCoin;