import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import PlImg from './plimg';
import {stInfo} from '../../../../API/startinfo.js';
import imgXch from '../../../../images/coins/xch.png';
import imgXi from '../../../../images/coins/xivi.png';
import AboutMnft from "../../modal/AboutMnft";

const ItemNFT = (p) => {
    //console.log(p);
    const [nftShow, setNftShow] = useState('');
    const { t } = useTranslation();
    const it = p.item;
    const imgC = it.coin_name==='XCH' ? imgXch : imgXi
    var price = it.coin_name==='XCH' ? Number(it.price)/1000000000000 : Number(it.price)/1000
    const royal = Number(it.royal)/100;
    var priceWR = price + price * (royal/100);
    const usdPrice = it.coin_name==='XCH' ? (p.rate==0 ? '- - -' : (priceWR * p.rate).toFixed(2)) : '- - -'; 

    price = price.toFixed(12).replace(/\.?0+$/,'');
    priceWR = priceWR.toFixed(12).replace(/\.?0+$/,'');
    //console.log(it);
    return (
        <div className="ditemNft">
        <button className="itemNft ani1" onClick={()=>{setNftShow([it.id_nft,it.id_col]);}}>
            <PlImg url={it.prev} coll={stInfo[3][it.id_col].coll_id} type={p.type} />
            <div className="d df">
                <div className="l wi5 mt8">
                    <div className="df jcl">
                        <div>
                            <span className="n">{p.side_nft===0 ? t('price') : t('offered')}</span>
                            <p className="price1"> {price.length<7 ? price : price.substr(0,6)+'..'} <span className='sp1'>{it.coin_name}</span><span className='ani1 fs2 tooltip'>{price}</span> </p>
                        </div>
                        <img src={imgC} />
                    </div>
                </div>
                <div className="r wi5 mt8">
                    <div className="df jcr">
                        <div>
                            <p className="price2">= {priceWR} {it.coin_name}</p>
                            <p className="price3">≈ {usdPrice} $</p>
                        </div>
                    </div>
                </div>
                <div className="c">
                    <div className="it_r">Royalties {royal}%</div>
                    <div className="line1"></div>
                </div>
            </div>
        </button>
        {nftShow !== '' && <AboutMnft nft={nftShow} close={setNftShow} />}
        </div>
    );
};

export default ItemNFT;