export const _generStr = (num) => {
    let chrs = 'abdehkmnpswxzABDEFGHKMNPQRSTWXZ123456789';
    let res = '';
    for (let i = 0; i < num; i++) {
        let pos = Math.floor(Math.random() * chrs.length);
        res += chrs.substring(pos,pos+1);
    }
    return res;
}
export const _load = (id) => {
    let bl = new Blob([document.getElementById(id).value],{type: "plain/text;charset=utf-8"});
    let a = document.createElement('a');
    a.href = URL.createObjectURL(bl);
    a.download = 'offer'+_generStr(20)+'.offer';
    a.click();
}
export const _copy = (id) => {
    document.getElementById(id).select();
    document.execCommand('copy');
}
export class _accW {
    static async take(id) {
    try {
        let tx = document.getElementById(id).value;
        console.log(tx);
        await window.chia.request({method: 'takeOffer', params: {offer: tx}});
        return 1
    } catch (err) {
        return 2
    }
    }
}