import React,{useState,useRef} from 'react';
import { useTranslation } from 'react-i18next';
import {stInfo} from '../../../../API/startinfo';
import SelectRow from './selectRow';
import SelectTab from './selectTab';



const ChoseDiv = ({chos,h11,chose,sele,close,list}) => {
    const { t } = useTranslation();
    var upList=[];
    if(list.length>0) {
        list.map((id)=>{
            //console.log(stInfo[chose][id]['name']);
            let name = chose===2 ? stInfo[chose][id]['name']['en'] : stInfo[chose][id]['name'];
            upList.push({'name':name,'id':Number(id), 'banner':stInfo[chose][id].banner});
        })
    } else {
        //stInfo[chose][id]
    }
    return (

        <div className="whF">
            <div className="upD1 w100 pad15">
                <h3 className="h3">{h11}</h3>
                {
                    chose===2 ? <SelectRow chos={chos} chose={chose} sele={sele} list={upList} /> : chose===3 && <SelectTab chos={chos} chose={chose} sele={sele} list={upList}/>
                }
            </div>
            <div className="downD1 w100 df jcc">
                <button className="ani1 btnser btnC2 fs2 br" onClick={()=>close(false)}>{t('back')}</button>
            </div>
        </div>
    );
};

export default ChoseDiv;