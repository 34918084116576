import React,{useState} from 'react';
import { useTranslation } from 'react-i18next';


const ResAddOff = ({res,btn}) => {
    const { t } = useTranslation();
    const defRes = (res) => {
        switch(res) {
            case 0:return t('try_later')
              break
            case 1:return t('confirmed')  
              break
            case 2:return t('onlyXi')  
              break
            case 3:return t('err_add_before')  
              break
            case 4:return t('no_tokene')  
              break
            case 5:return t('err_add_have_date')  
              break
            case 6:return t('inval_offer')  
              break
            case 7:return t('offer_to_queue')  
              break
            default:return t('')            
              break
          }
    }
    var response=defRes(res);
    return (
        <p className='fs3 pa cc win_noti'>
            {response}
            <button onClick={()=>btn(false)} className='btno2 ani1'style={{display:'block'}}>OK</button>
        </p>

    );
};

export default ResAddOff;