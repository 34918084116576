import React, {useState,useEffect} from 'react';
import LeftBar from '../components/market/leftbar';
import RightBar from '../components/market/rightbar';
import ListNftSale from '../API/listNftSale.js';
import RateXch from '../API/rateXch.js';
import {MaProvider,useMaSelector,useMaUpdate} from  '../context/marketCont';
import {SerchCProvider} from '../context/serchC.js';
import { useTranslation } from 'react-i18next';

const Market = () => {
    useEffect(()=>{
        const d1=document.getElementById('navj7');
        if(d1.style.right != '') {
            d1.style = "";
            document.getElementById('mrl11').classList.remove('mrlA1');
            document.getElementById('mrl22').classList.remove('mrlA2');
            document.getElementById('mrl33').classList.remove('mrlA3');
        }
    },[]);
    const { t } = useTranslation();
    //const [rate, setRate] = useState('');
    /*async function _upRate() {
        const res = await RateXch.show();
        const updateValue = useMaUpdate();
        updateValue(res.data);
        //setRate(res.data)  
    }*/
    /*useEffect(()=>{
    const asy = async()=> {
        try {
            const ratexch2 = await RateXch.show();
            const updateValue2 = useMaUpdate();
            updateValue2(ratexch2.data);
            //setRate(ratexch.data)
        }
        catch(err) {
            console.log(err);
        }
    }
    asy();
    },[]);*/
    return (
        <div className="page1">
            <MaProvider initialState={{ value: "" }}>
                <SerchCProvider initialState={{ value: {'type':1,
                'of':{
                    2:{'name':t('typenft'),'id':0,'targ':2,'list':[]},
                    3:{'name':t('collection'),'id':0,'targ':3,'list':[]}
                },
                'req':{
                'coin':{'name':'Coin','id':0}
                },
                'atr':{
                }} 
                }}>
                    <LeftBar />
                    <RightBar />
                </SerchCProvider>
            </MaProvider>
        </div>
    );
};

export default Market;