import React,{useState} from 'react';
import { useTranslation } from 'react-i18next';
import { Transition } from 'react-transition-group';


const BdWallet = ({refa,cl}) => {
    const { t } = useTranslation();
    const [btnCh,setBtnCh]=useState(1);
    return (
        <div ref={refa} className={`fs6 ani1 pa wd1 cc ${cl}`}>
Soon
        </div>

    );
};

export default BdWallet;