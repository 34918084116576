import React from 'react';
import { useTranslation } from 'react-i18next';

const BtnSelect4 = ({val,act,nowColl}) => {
    const { t } = useTranslation();
    if(nowColl===0) {
        var css = 'btnChoN',tx1 = 'co3',tx2 = 'co4';
    } else {
        var css = 'btnCho',tx1 = 'co1',tx2 = 'co2';
    }
return (
    <button className={`ani1 ${css} pad5 br`} onClick={()=>{nowColl!==0 && act(5)}}>
        <div className={`${tx1} fs2`}>{t('attributes')}</div>
        <div className={`${tx2} fs1`}>{nowColl===0 ? t('first_sel_coll') : Object.keys(val).length < 1 ? t('select') : t('change')}</div>
    </button>
);
};

export default BtnSelect4;