import React,{useState} from 'react';
import { useTranslation } from 'react-i18next';

const BtnSelect1 = ({name,val,targ,act}) => {
    const { t } = useTranslation();
return (
    <button className='ani1 btnCho pad5 br' onClick={()=>{act(targ)}}>
        <div className='co1 fs2'>{name}</div>
        <div className='co2 fs1'>{val==='' ? t('select') : val}</div>
    </button>
);
};

export default BtnSelect1;