import React from 'react';
import Img3D from './Img3D';
import { stInfo } from '../../../API/startinfo';

const Nftcartoff = ({nft}) => {
    //console.log(nft);
    return (
        <div className='maNft'>
            <div className='pl_nft'>    
                <Img3D url={nft.prew} coll={stInfo[3][nft.col_id].coll_id} css='tac ani1 pa cc whF' cc='' />
            </div>
            <div className="it_r tac">Royalties 3%</div>
        </div>
    )
};

export default Nftcartoff;