import Market from "./pages/Market";
import Projects from "./pages/Projects";
import About from "./pages/about";
import Chia_guides from "./pages/chia_guides";
import Airdrops from "./pages/Airdrops";


export const pubRoutes = [
    {path: '/', element: <Market />, exact: true},
    {path: '/market', element: <Market />, exact: true},
    {path: '/projects', element: <Projects />, exact: true},
    {path: '/airdrops', element: <Airdrops />, exact: true},
    {path: '/about', element: <About />, exact: true},
    {path: '/chia_guides', element: <Chia_guides />, exact: true},
]