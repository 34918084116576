import React,{useState,useRef} from 'react';
import { useTranslation } from 'react-i18next';
import { Transition } from 'react-transition-group';
import {stInfo} from '../../../../../API/startinfo';


const SelectTp = ({chos, chose, idn, sele}) => {
    const [subMenu,setSubMenu] = useState(false);
    const idr=useRef(null);
    var subArr = {}; 
    const openLi = ()=>{

    }
    {Object.keys(stInfo[chose]).map((id)=>{
        //console.log('ITS -------');
        //console.log(idn);

        if(stInfo[chose][id]['parent']==idn) {
            //console.log('Have');
            subArr[id] = stInfo[chose][id]
        }  
    })}
//console.log(subArr);
var bg102 = subMenu===true ? 'bg102' : ''
    return (
        <div>
                <button className={`ani1 btncoll pad5 ${bg102}`} onClick={()=>(
                    Object.keys(subArr).length ===0 ? sele(chos,chose,idn) : setSubMenu(!subMenu)
                    )}>
                    <p className="p1">{stInfo[chose][idn]['name']['en']}</p>
                    {Object.keys(subArr).length !==0 && (subMenu===false
                        ? <span className='onListbtn'><i className="fa fa-angle-down" aria-hidden="true"></i></span>  
                        : <span className='onListbtn'><i className="fa fa-angle-up" aria-hidden="true"></i></span>   )          
                    } 
                </button>
                <Transition nodeRef={idr} unmountOnExit={true} in={subMenu} timeout={300} >
                    {state => (
                        <div ref={idr} className={`ani1 divLiCo tr_Li-${state}`}>
                        <button className="ani1 btncoll pad5 w1m0" onClick={()=>sele(chos,chose,idn)}>
                                <p className="p1">- All - {stInfo[chose][idn]['name']['en']}</p>
                        </button>                      
                        {Object.keys(subArr).map((id2)=>(
                            <button key={`v3gv3_${id2}`} className="ani1 btncoll pad5 w1m0" onClick={()=>sele(chos,chose,id2)}>
                                <p className="p1">{stInfo[chose][id2]['name']['en']}</p>
                            </button>
                        ))                 
                        } 
                        </div>                      
                    )}
                </Transition>
        </div>
    );
};

export default SelectTp;