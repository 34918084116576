import React,{useEffect,useState,useRef} from 'react';
import Nftcartoff from './Nftcartoff';
import CoinCartOff from './CoinCartOff';
import {_generStr,_load,_copy,_accW} from '../../../func/ActOffer.js';
import WalList from './WalList.jsx';

const Cartoff = ({ofs}) => {
    //console.log(ofs);
    const idI = 'oi_'+_generStr(9);
    const [walOp,setWalOp] = useState('');
    const refid=useRef(idI);
    return (
        <div className='dt954 ani1'>
            {walOp !== '' && <WalList id={walOp} closex={setWalOp} />}
            <div className='class="br jcc df'>
                <div className='iNftNft2 ani1'>
                  {
                    Object.keys(ofs.of).map((id)=>(
                        ofs.of[id].nft_id ? <Nftcartoff key={`nf4o_${_generStr(9)}_$`} nft={ofs.of[id]} /> : <CoinCartOff key={`co4o_${_generStr(9)}`} coin={ofs.of[id]} />
                    ))
                  }
                </div>
                <div><i className="fs4 fa fa-long-arrow-right" aria-hidden="true"></i></div>
                <div className='iNftNft2 ani1'>
                  {
                    Object.keys(ofs.req).map((id)=>(
                        ofs.req[id].nft_id ? <Nftcartoff key={`nf4r_${_generStr(9)}`} nft={ofs.req[id]} /> : <CoinCartOff key={`co4r_${_generStr(9)}`} coin={ofs.req[id]} />
                    ))
                  }
                </div>
            </div>
            <div className="DoffAct2 tac">
                <input type="text" className="inp_o0" id={refid.current} defaultValue={ofs.o_text} />
                <button onClick={()=>_load(refid.current)} className="co6 br fs01 btnDoff ani1" style={{width:'auto',margin:'10px'}}>
                    <i className="co6 w100 fs4 fa fa-download" aria-hidden="true"></i>Download<br />Offer
                </button>
                <button onClick={()=>_copy(refid.current)} className="co6 br fs01 btnDoff ani1" style={{width:'auto',margin:'10px'}}>
                    <i className="co6 w100 fs4 fa fa-clipboard" aria-hidden="true"></i>Copy to<br />Clipboard
                </button>
                <button onClick={()=>setWalOp(refid.current)} className="ffv8 co6 br fs01 btnDoff ani1" style={{width:'auto',margin:'10px'}}>
                    <i className="co6 w100 fs4 fa fa-check" aria-hidden="true"></i>Accept in<br />Wallet
                </button>
            </div>
        </div>
    )
};

export default Cartoff;