import axios from "axios";
import {paths0} from "./paths0.js";

export default class aboutNft {
    static async show(nft,coll) {
        //console.log(coll);
        var bod = {
            nft_id:nft,
            coll_id:coll
        };
        try {
        const response = await axios.post(paths0.serv+'nftinfo.php', bod);
        //console.log(response);
          return response;
        } catch(err) {
            console.log(err);
        }
    }
}