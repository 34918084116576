import React,{useState,useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import BtnLm from '../ui/buttons/btnlm/btnLm';
import BtnAp from '../ui/buttons/btnap';
import SearchL from '../ui/elem/search/SearchL';
import Rates from '../ui/elem/rates/rates';
import {useSerchCSelector} from '../../context/serchC.js';
import FiltD from '../ui/buttons/blk/FiltD.jsx';
import {Filt} from '../../context/filt.js';
import AboutMnft from '../ui/modal/AboutMnft.jsx';

const LeftBar = (pr) => {
    const { t } = useTranslation();
    const [vNft,setVnft] = useState('');
    const modestr = useSerchCSelector((state) => state.value);
    //console.log('LEFTBAR');

    const _opNft = (nft) => {
        setVnft(nft);
    }
    return (
        <div className="bar1 ani1" id="lfBar">
            <div className="df"><h1 className="h11">Market</h1><div className="h11_l"></div></div>
            <FiltD key="k66k" />    
            <BtnAp key="k3k" txt={t('addoffer')} />
            <SearchL key="serch01" showNft={setVnft} />
            <div className="lm_rates">
                <div className='v7k4 cn'>Chia blockchain <i className="fa fa-chevron-down" aria-hidden="true"></i></div>
                <Rates key="rate01" xch={pr.rate} upset={pr.upset}/>
            </div>
            {vNft != '' && <AboutMnft nft={[vNft.id,vNft.col_id]} close={setVnft} />}


        </div>
    );
};

export default LeftBar;