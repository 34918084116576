import React, {useState} from 'react';
import Sochial from './Sochial';
import { useTranslation } from 'react-i18next';


const SochDiv = () => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const WH = open ? document.getElementById('dl63f3v3').clientWidth + 10
    : 0

    return (
        <div className='DS5f df dp0'>
            {open && <button onClick={()=>setOpen(false)} className='hov5 whF lt0' />}
            <button id='ic3kg4' className='btn6s5 ani1' onClick={()=>setOpen(!open)}>
                <i className={`ani1 i6k fa fa-chevron-circle-left ${open===true && 'r6l2'}`} aria-hidden="true"></i><br />{t('subscribe')}
            </button>
            <Sochial id='dl63f3v3' css1='vbre pa' />
            <div className='ani1' style={{height:'1px',width:WH}}></div>
        </div>
    )
};

export default SochDiv;