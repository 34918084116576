import React,{useEffect,useState,useRef} from 'react';
//import * as THREE from 'three'; 
import {Transition} from 'react-transition-group';
import { useTranslation } from 'react-i18next';
import Loader from '../elem/itemnft/loader';
import AboutNft from '../../../API/aboutNft';
import {AtrStInfo} from '../../../API/startinfo';
import InfNftA from './InfNftA';
import OfNftA from './ofNftA'
import OffersNft from '../../../API/offersNft';

const InfOf = ({nftInf}) => {
    const { t } = useTranslation();
    const [chose, setChose] = useState(1);
    const [offers,setOffers] = useState('');

    useEffect(()=>{
        
        const asy = async()=> {
            try {
                //console.log(nftInf);
                const res = await OffersNft.show(nftInf['at_nft_id'],nftInf['col_id']);
                setOffers(res.data.nft.offers);
                //console.log(res.data.nft.offers);
            }
            catch(e) {
                setOffers('0');
                console.log('err');
            }
            finally {
                //setIsLoad(true);
            }
            }
        asy();
    },[]);

    return (
    <div className='nOff'>
        <div className='navNft'>
            <button onClick={()=>setChose(1)} className={`ani1 btn91 fs4 ${chose===1&&'ac91'}`}>{t('info')}</button>
            <button onClick={()=>setChose(2)} className={`ani1 btn91 fs4 ${chose===2&&'ac91'}`}>Offers</button>
        </div>
        {chose===1 ? <InfNftA nftInf={nftInf} /> : <OfNftA offers={offers} />
        }
    </div>
        )
};

export default InfOf;