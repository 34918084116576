import React, {useState,useRef} from 'react';
import './btnLm.css';
import Mod1 from '../../modwin/Mod1';
import Mod2 from '../../modwin/Mod2';
import { Transition } from 'react-transition-group';

const BtnLm = (props) => {
    const idn=useRef(null)
    const [isMOpen,setMOpen] = useState(false)

    const CloseAct = ()=>{
        if(isMOpen===true){setMOpen(false)}
        document.removeEventListener('click', _lMenuEvent, false);
    }
    const _lMenuEvent =ee=> {
        if(idn.current) {
            if (!idn.current.contains(ee.target)) {
                CloseAct();
            }
        }
    }
return (

    <div className="wr ani1">
        <button className="btn1 hbtn1 ani1" onClick={()=>{setMOpen(true)}}>
            <div className="btnL">
                <div className="n">NFT <i className='co1 fs2 fa fa-arrows-h' aria-hidden='true'></i> {props.txt}</div>
            </div>
            <div className="btnR">
                <div className = {`c ani1 ${props.cssc}`}></div>
                <i className="ar_R ani1 fa fa-caret-right" aria-hidden="true"></i>
            </div>
        </button>
        <Transition nodeRef={idn} unmountOnExit={true} in={isMOpen} timeout={300} onEntered={()=>{
            document.addEventListener('click', _lMenuEvent,false);
        }} onExited={()=>{
           // document.removeEventListener('click', _lMenuEvent, false);
        }}>
            {state => (
                <div ref={idn} className={`lvv1 ani1 dv_wr dv-${state}`}>
                    {props.act===1 ?<Mod1 key={props.onc} closeAct={CloseAct} />
                                   :<Mod2 key={props.onc} closeAct={CloseAct} />
                    }
                </div>
            )}
      </Transition>
    </div>

);
};

export default BtnLm;