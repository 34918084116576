import React,{useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import {AbImg} from '../API/Airinf';
import Sochial from '../components/sochial/Sochial';
import {NavLink} from "react-router-dom";

const About = () => {
    useEffect(()=>{
        const d1=document.getElementById('navj7');
        if(d1.style.right != '') {
            d1.style = "";
            document.getElementById('mrl11').classList.remove('mrlA1');
            document.getElementById('mrl22').classList.remove('mrlA2');
            document.getElementById('mrl33').classList.remove('mrlA3');
        }
    },[]);
    const { t } = useTranslation();
    var ff;
    if(window.innerWidth < 1050) {
        ff=1;
    } else {ff=0;}
    return (
    <div className='v7n3' id='barr6'>
        <h1 className="h1p6 tac fs5">{t('about')}</h1>
        <div className='d6h4 tac'>
            <p className='p2l6 fs4'>{t('ab1')}</p>
            <div className='df jcc ver6p' style = {{marginTop: '30px'}}>
                <p className='p3h7'>{t('ab2')}</p>
                <div className='n0g6'>
                    {ff===1 ? <i className="fa fa-arrow-circle-down" aria-hidden="true"></i> : <i className="fa fa-arrow-circle-right" aria-hidden="true"></i>}
                    </div>
                <p className='p3h7'>{t('ab3')}</p>
                <div className='n0g6'>
                    {ff===1 ? <i className="fa fa-arrow-circle-down" aria-hidden="true"></i> : <i className="fa fa-arrow-circle-right" aria-hidden="true"></i>}
                </div>
                <p className='p3h7'>{t('ab4')}</p>
            </div>
            <p className='p2g7'>{t('ab5')}</p>
            
            <NavLink to={'/chia_guides'} className={"ani1 a2l6"}>
                {t('ab6')}
            </NavLink>
            <p className='p2g7'>{t('ab7')} {t('ab8')}</p>
            <ul className='ul1'>
                <li>-- {t('ab9')} --</li>
                <li>-- {t('ab10')} --</li>
                <li>-- {t('ab11')} --</li>
            </ul>
            {t('ab12')}!
            <h2 className='h2l4 fs5'>{t('abh13')}</h2>
            <div className='dc74'>
                <img src = {AbImg[0]} />
                <p className='p2l6'>{t('ab14')}</p>
                <span className='s7v3 fs4'>{t('ab15')}</span>
            </div>
            <div className='dc74'>
                <img src = {AbImg[1]} />
                <p className='p2l6'>{t('ab16')}</p>
            </div>
            <div className='dc74'>
                <img src = {AbImg[2]} />
                <p className='p2l6'>{t('ab17')}</p>
            </div>

            <a href='https://garage.xivion.io' className='ani1 a2l6' target="_blank">{t('ab18')}</a>
            <h2 className='h2l4 fs5'>{t('abh19')}</h2>
            <p className='p2l6'>{t('ab20')}</p>
            <p className='p2l6 ltd4'>{t('ab21')}</p>
            <Sochial />
        </div>
    </div>
    );
};

export default About;