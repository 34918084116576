import React from 'react';
import { useTranslation } from 'react-i18next';
import BtnNav from '../ui/buttons/btnnav';
import {Link} from "react-router-dom";
import Sochial from "../sochial/Sochial";
import Langs from '../header/langs';

const NavA = () => {
    const { t } = useTranslation();
    const nav_links = {
        "market":{"tx": "market","noact":false},
        "projects":{"tx": "Projects","noact":true},
        "airdrops":{"tx": "airdrops","noact":false},
        "about":{"tx": "about","noact":false},
        "chia_guides":{"tx": "chia_guides","noact":false}
    };
    return (
        <nav className="ani1 df lass1" id='navj7'>

            {window.matchMedia("(max-width: 1050px)").matches && <Langs mob={1}/>}
            {Object.keys(nav_links).map((link)=>(
                <BtnNav key={'p_'+link} lTo={nav_links[link]['tx']} text={t(nav_links[link]['tx'])} noact={nav_links[link]['noact']} />
            ))}
            <a className="ani1 btnNavUp bt7cj fs2" href="https://xivion.io" target="_blank">{t('aboutX')}</a>
            <div className='fml1'>
            <Sochial />
            </div>
        </nav>
    );
};

export default NavA;