import React,{useState,useRef} from 'react';
import { useTranslation } from 'react-i18next';
import {stInfo} from '../../../../API/startinfo';

const SelectTab = ({chos,chose,sele,list}) => {
    //console.log(list);
    //console.log(chose);
    const { t } = useTranslation();
    if(list.length > 0) {
        return (
            <div>
                {list.map((coll)=>(
                    <button key={`colC_${coll.id}`} className="ani1 btncoll pad5" onClick={()=>sele(chos,chose,coll.id)}>
                        <img src={stInfo[3][coll.id].banner} />
                        <p className="p1">{stInfo[3][coll.id].name}</p>
                    </button>   
                ))}
            </div>
        );
    }
    else {
        return (
            <div>
                {Object.keys(stInfo[3]).map((coll)=>(
                    <button key={`colC_${coll}`} className="ani1 btncoll pad5" onClick={()=>sele(chos,chose,coll)}>
                        <img src={stInfo[3][coll].banner} />
                        <p className="p1">{stInfo[3][coll].name}</p>
                    </button>              
                ))}
            </div>
        );
    }
};

export default SelectTab;