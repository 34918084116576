import React,{useRef,useEffect} from 'react';
import { useTranslation } from 'react-i18next';
//import img1 from '../images/about/ur1.webp';
import img2 from '../images/about/ur2.webp';
import img3 from '../images/about/ur3.webp';
import img4 from '../images/about/ur4.webp';
import img5 from '../images/about/ur5.webp';
import img6 from '../images/about/ur6.webp';
import img7 from '../images/about/ur7.webp';
import img8 from '../images/about/ur8.webp';
import img9 from '../images/about/ur9.webp';
import img10 from '../images/about/ur10.webp';
import img11 from '../images/about/ur11.webp';
import img12 from '../images/about/ur12.webp';
import img14 from '../images/about/ur14.webp';
import img15 from '../images/about/ur15.webp';
import img16 from '../images/about/ur16.webp';

const Chia_guides = () => {
    useEffect(()=>{
        const d1=document.getElementById('navj7');
        if(d1.style.right != '') {
            d1.style = "";
            document.getElementById('mrl11').classList.remove('mrlA1');
            document.getElementById('mrl22').classList.remove('mrlA2');
            document.getElementById('mrl33').classList.remove('mrlA3');
        }
    },[]);
    const { t } = useTranslation();
    const ref1 = useRef();
    const ref2 = useRef();
    const ref3 = useRef();
    const ref4 = useRef();
    const ref5 = useRef();
    const ref6 = useRef();
    const _step = (r)=> {
        const d1 = document.getElementById('lfBar');
        setTimeout(()=>{
        document.getElementById('barr6').scrollTo(0, (r.current.offsetTop - document.getElementById('head1').offsetHeight));
        },100);
        d1.style = "";
        document.getElementById('mrl1').classList.remove('mrlA1');
        document.getElementById('mrl2').classList.remove('mrlA2');
        document.getElementById('mrl3').classList.remove('mrlA3');
        document.getElementById('mrl3').classList.add('mrlz3');
    }
    return (
        <div className="page1">
            <div className="bar1 ani1" id="lfBar">
                <div className="df"><h1 className="h11">{t('chia_guides')}</h1><div className="h11_l"></div></div>
                <button className="btn1 hbtn1 ani1" onClick={()=>_step(ref1)}>{t('ax1')}</button>
                <button className="btn1 hbtn1 ani1" onClick={()=>_step(ref2)}>{t('ax2')}</button>
                <button className="btn1 hbtn1 ani1" onClick={()=>_step(ref3)}>{t('ax3')}</button>
                <button className="btn1 hbtn1 ani1" onClick={()=>_step(ref4)}>{t('ax4')}</button>
                <button className="btn1 hbtn1 ani1" onClick={()=>_step(ref5)}>{t('ax5')}</button>
                <button className="btn1 hbtn1 ani1" onClick={()=>_step(ref6)}>DID</button>
            </div>
            <div className="bar2" id='barr6'>
            <div className='dv6' ref={ref1}>
            <div className='p2'>
                <h2>{t('ax1')}</h2>
                {t('ax6')}
                {t('ax55')}
                {t('ax56')} <a href="https://www.chia.net/" target="_blank">Chia.net</a><br></br><br></br>
                {t('ax57')}<br></br><br></br>
                - <a href = "https://okx.com/join/8886355" target='_blank'>OKX</a><br></br>
                - <a href = "https://www.gate.io/signup/AwBDUAoJ?ref_type=103" target='_blank'>Gate.io</a><br></br>
                - <a href = "https://www.htx.com/invite/ru-ru/1f?invite_code=exti8223 " target='_blank'>HTX</a><br></br>
                - <a href = "https://www.kucoin.com/r/rf/QBS1NPMP" target='_blank'>KuCoin</a><br></br>
                - <a href = "https://www.mexc.com/register?inviteCode=12URwz" target='_blank'>MEXC</a><br></br>
                - ...
            </div>
            </div>
                <div className='dv6' ref={ref2}>
                    <h2>{t('ax2')}</h2>
                    1. {t('ax7')} <a href = 'https://www.chia.net/downloads/' target='_blank'>Chia</a> {t('or')} <a href = 'https://github.com/Chia-Network/chia-blockchain/releases/' target='_blank'>GitHub</a>
                    <br></br>
                    2. {t('ax8')}<br></br><br></br>
                    {t('ax9')}<br></br><br></br>
                    3. {t('ax10')}<br></br><br></br>
                    <img src={img2}></img><br></br>
                    {t('ax11')}<br></br><br></br>
                </div>
                <div className='dv6' ref={ref3}>
                    <h2>{t('ax3')}</h2>
                    {t('ax12')}<br></br>
                    {t('ax13')}<br></br><br></br>
                    <img src={img3}></img><br></br>
                    {t('ax14')}<br></br><br></br>
                    **{t('ax15')}<br></br>
                    ***{t('ax16')}<br></br><br></br>
                    {t('ax17')}<br></br>
                    {t('ax18')}<br></br><br></br>
                    <img src={img4}></img>
                </div>
                <div className='dv6' ref={ref4}>
                    <h2>{t('ax4')}</h2>
                    {t('ax19')}<br></br>
                    {t('ax20')}<br></br>
                    {t('ax21')}<br></br>
                    {t('ax22')}<br></br>
                    {t('ax23')}<br></br><br></br>
                    <h3>{t('ax24')}</h3>
                    {t('ax25')} <a href="https://garage.xivion.io" target="_black">Xivion Garage</a><br></br><br></br>
                    <img src={img5}></img>
                    {t('ax26')}<br></br>
                    - {t('ax27')}<br></br>
                    - {t('ax28')}<br></br>
                    - {t('ax29')}<br></br><br></br>
                    {t('ax30')}<br></br>
                    {t('ax31')}<br></br><br></br>
                    <img src={img6}></img>
                    <br></br><br></br>
                    {t('ax32')}<br></br>
                    <br></br>
                    <img src={img7}></img><br></br>
                    {t('ax33')}<br></br>
                    <h3>{t('ax34')}</h3>
                    {t('ax35')}<br></br><br></br>
                    {t('ax36')}
                    <br></br>
                    <img src={img8}></img><br></br>
                    <img src={img9}></img><br></br>
                    - {t('ax37')}<br></br>
                    - {t('ax38')}<br></br><br></br>
                    {t('ax39')}<br></br>
                    **{t('ax40')}<br></br><br></br>
                    {t('ax41')}<br></br><br></br>
                    <img src={img10}></img><br></br>
                </div>
                <div className='dv6' ref={ref5}>
                    <h2>{t('ax5')}</h2>
                    {t('ax43')}<br></br>
                    {t('ax44')}<br></br><br></br>
                    <img src={img11}></img><br></br><br></br>
                    <img src={img12}></img><br></br>
                    {t('ax45')}<br></br><br></br>
                </div>
                <div className='dv6' ref={ref6}>
                    <h2>DID</h2>
                    {t('ax46')}<br></br><br></br>
                    {t('ax47')}<br></br><br></br>
                    {t('ax48')} <a href="https://mintgarden.io/" target="_blank">MintGarden</a><br></br>
                    {t('ax49')}<br></br>
                    {t('ax50')}<br></br>
                    {t('ax51')}<br></br>
                    <img src={img14}></img><br></br>
                    {t('ax52')}<br></br><br></br>
                    {t('ax53')}<br></br>
                    {t('ax54')}<br></br><br></br>
                    <img src={img15}></img><br></br><br></br>
                    <img src={img16}></img><br></br><br></br><br></br>
                </div>
            </div>
        </div>
    );
};

export default Chia_guides;