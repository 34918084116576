import axios from "axios";

export default class RateXch {
    static async show() {
        try {
        const response = await axios.post('https://xchscan.com/api/chia-price')
        return response;
        } catch(err) {
            //console.log(err);
        }
    }
}