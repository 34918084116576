import React,{useState,useRef,useContext} from 'react';
import { useTranslation } from 'react-i18next';
import { Transition } from 'react-transition-group';

import ChoseDiv from './choseDiv.jsx';
import ChoseDivAtr from './choseDivAtr.jsx'
import BtnSelect1 from '../BtnSelect1.jsx';
import BtnSelect2 from '../BtnSelect2.jsx';
import BtnSelect4 from '../BtnSelect4.jsx';
import {stInfo} from '../../../../API/startinfo';

import {useSerchCSelector} from '../../../../context/serchC.js';
import {Filt} from '../../../../context/filt.js';


const Choise = ({chos,forSelect,setForSelect}) => {
    const [selMin,selMax]=[2,3];
    const { t } = useTranslation();
    const idn=useRef(null);

    const [isopenDiv, setOpenDiv] = useState(false);
    const [chosediv,setChosediv] = useState(-1);

    const openDiv = (chose) => {
        setOpenDiv(!isopenDiv);
        setChosediv(chose);
    }

    const listForSelect = (side,targ,id) => {
        var obj = JSON.parse(JSON.stringify(forSelect));
        //console.log(forSelect);
        if(obj[side][targ].id!==id) {
            obj[side][targ].id=id;            
            for(let i=targ+1; i<=selMax; i++) {
                let nArr=[];                
                Object.keys(stInfo[i]).map((c)=>{
                    if(stInfo[i][c].typenft.indexOf(Number(id)) !=-1) {
                        if(nArr.indexOf(c) === -1) {
                            nArr.push(Number(c));
                        }
                    }
                });
                obj[side][i].list=nArr;
                //if(nArr.indexOf(Number(forSelect[side][3].id)) === -1) {
                    obj[side][3].id = 0;
                //}
            }
            if(forSelect.type===1||forSelect.type===0) {
                //console.log(forSelect[side][3].id);
                //console.log(obj[side][3].id);
                if(forSelect[side][3].id != obj[side][3].id) {
                    obj.atr = {};
                }
            }
            setForSelect(obj);
        }
        setOpenDiv(false);
    }
    const add_atr = (atr) => {
        var obj = JSON.parse(JSON.stringify(forSelect));
        obj.atr = atr;
        setForSelect(obj);
        setOpenDiv(false);
    }

    const addName =(targ,id) => {
        if(id===0) {return ''}
        if(targ===2) {return stInfo[targ][id]['name']['en'] }
        if(targ===3) {return stInfo[targ][id]['name'] }
    }

    const nowColl = forSelect[chos][3].id===0 ? 0 : forSelect[chos][3].id
    return (
        <div className="div1 br pad15">
            <div className='pr tac pad5 br hNf'>NFT</div>
                <Transition nodeRef={idn} unmountOnExit={true} in={isopenDiv} timeout={300} >
                    {state => (
                        <div ref={idn} className={`ani1 choseDiv pa whF z7 upS-${state}`}>
                        {chosediv!==-1 && chosediv===5 
                            ? <ChoseDivAtr nowColl={nowColl} nameColl={stInfo[3][nowColl].name} atr ={forSelect.atr} add_atr={add_atr} close={setOpenDiv} />
                            : <ChoseDiv list={forSelect[chos][chosediv].list} h11={forSelect[chos][chosediv].name} chos={chos} chose={chosediv} sele={listForSelect} close={setOpenDiv} />}
                        </div>
                    )}
                </Transition>
            <div style = {{marginTop: '-25px'}}>
                <BtnSelect2 key={`chBtnH1`} name={t('category')} val={t('games') +'/ 3D / ' + t('action') +' / ...'} />
                <BtnSelect2 key={`chBtnH2`} name={t('project')} val={'Xivion Farm Game'} />
            {Object.keys(forSelect[chos]).map(arr=>
                <BtnSelect1 
                    key={`choBtn_${forSelect[chos][arr].targ}`} 
                    name={forSelect[chos][arr].name} 
                    val={addName(forSelect[chos][arr].targ,forSelect[chos][arr].id)}
                    targ={forSelect[chos][arr].targ} 
                    act={openDiv} />)
            }
            {forSelect.type===1&&
                <BtnSelect4 key={`chBtnH4`} val={forSelect.atr} act={openDiv} nowColl={nowColl} />    
            }
            </div>
        </div>
    );
};

export default Choise;