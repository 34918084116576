import React, {useState,useRef} from 'react';
import PlImg from './plimg';
import AboutMnft from "../../modal/AboutMnft";
import WalList from '../../modal/WalList';
import {_generStr,_load,_copy,_accW} from '../../../../func/ActOffer.js';
import { stInfo } from '../../../../API/startinfo.js';

const ItemNftNft = (p) => {
    const ref=useRef(null);
    const [walOp,setWalOp] = useState('');

    const [nftShow, setNftShow] = useState('');
    const it = p.item;
    const of_royal = Number(it.of_royal)/100;
    const req_royal = Number(it.req_royal)/100;

    const idI = 'i_'+_generStr(9);
    return (
        <div className="br dNftNft df ani1">
        {walOp !== '' && <WalList id={walOp} closex={setWalOp} />}
        <button className="iNftNft ani1" onClick={()=>{setNftShow([it.of_nft,it.of_col]);}}>
            <PlImg url={it.of_prev} coll={stInfo[3][it.of_col].coll_id} type={p.type} />
            <div className="it_r">Royalties {of_royal}%</div>
        </button>
        <div className='arrow1'><i className="fs5 fa fa-long-arrow-right" aria-hidden="true"></i></div>
        <button className="iNftNft ani1" onClick={()=>{setNftShow([it.req_nft,it.req_col]);}}>
            <PlImg url={it.req_prev} coll={stInfo[3][it.req_col].coll_id} type={p.type} />
            <div className="it_r">Royalties {req_royal}%</div>
        </button>

        <div className="DoffAct">
            <input type="text" className='inp_o0' defaultValue={it.o_text} id={idI} />
            <button onClick={()=>_load(idI)} className="co6 br fs01 btnDoff ani1"><i className="co6 w100 fs4 fa fa-download" aria-hidden="true"></i>Download<br />Offer</button>
            <button onClick={()=>_copy(idI)} className="co6 br fs01 btnDoff ani1"><i className="co6 w100 fs4 fa fa-clipboard" aria-hidden="true"></i>Copy to<br />Clipboard</button>
            <button onClick={()=>setWalOp(idI)} className="ffv8 co6 br fs01 btnDoff ani1"><i className="co6 w100 fs4 fa fa-check" aria-hidden="true"></i>Accept in<br />Wallet</button>
        </div>
        {nftShow !== '' && <AboutMnft nft={nftShow} close={setNftShow} />}
        </div>
    );
};

export default ItemNftNft;