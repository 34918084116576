import React,{useEffect,useState,useRef} from 'react';
//import * as THREE from 'three'; 
import {Transition} from 'react-transition-group';
import { useTranslation } from 'react-i18next';
import Loader from '../elem/itemnft/loader';
import {AtrStInfo} from '../../../API/startinfo';
import InfNftA from './InfNftA';
import Cartoff from './cartoff';

const OfNftA = ({offers}) => {
    //console.log(offers);
    const { t } = useTranslation();
    const [chose, setChose] = useState(1);
    const off = offers!=='0' ? offers.offers : []
    const bid = offers!=='0' ? offers.bids : []
    if(offers==='0') { return (<div className='fs4 tac' style={{marginTop: '35px'}}>{t('err')}</div>)} else {
    return (
    <div className='vr4g'>
        <h2 className='h274 fs4'>Offers({Object.keys(off).length})</h2>
        {Object.keys(off).length > 0 && Object.keys(off).map((i)=>(<Cartoff key={`ofb${i}`} ofs={off[i]} />)) }
        <h2 className='h274 fs4'>Bids({Object.keys(bid).length})</h2>
        {Object.keys(bid).length > 0 && Object.keys(bid).map((i)=>(<Cartoff key={`reqb${i}`} ofs={bid[i]} />)) }
    </div>
    )
    }
};

export default OfNftA;