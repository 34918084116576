import React,{useState,useRef} from 'react';
import { Transition } from 'react-transition-group';

import ChoseDivCoin from './choseDivCoin.jsx';
import BtnSelect3 from '../BtnSelect3.jsx';
import {CoinsStInfo} from '../../../../API/startinfo';

const Choise = ({chos,forSelect,setForSelect}) => {
    const idn=useRef(null);
    const [isopenDiv, setOpenDiv] = useState(false);

    const listForSelect = (side,id) => {
        var obj=forSelect;
        if(obj[side]['coin'].id!==id) {
            obj[side]['coin'].id=id;
            setForSelect(obj);
        }
        setOpenDiv(false);
    }

    const addName =(id) => {
        if(id===0) {return ''}
        return CoinsStInfo[id];
    }

    return (
        <div className="div1 br pad15">
            <div className='pr tac pad5 br hNf'>Coin</div>
                <Transition nodeRef={idn} unmountOnExit={true} in={isopenDiv} timeout={300} >
                    {state => (
                        <div ref={idn} className={`ani1 choseDiv pa whF z7 upS-${state}`}>
                            <ChoseDivCoin chos={chos} sele={listForSelect} close={setOpenDiv} />
                        </div>
                    )}
                </Transition>
            
            <div style = {{marginTop: '-25px'}}>
                <BtnSelect3 
                    key={'cecoin'} 
                    chos={chos} 
                    val={addName(forSelect[chos]['coin'].id)}
                    id={forSelect[chos]['coin'].id} 
                    act={setOpenDiv} />
            </div>
        </div>
    );
};

export default Choise;