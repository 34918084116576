import React,{useEffect,useState} from 'react';
import Loader from '../itemnft/loader';
import PlImg from '../itemnft/plimg.jsx';
import sercheNft from '../../../../API/sercheNft.js';
import { useTranslation } from 'react-i18next';

const Resserch = ({serch,close,showNft}) => {
    const { t } = useTranslation();
    const [nft,setNft] = useState('');

    useEffect(()=> {
        const asy = async()=> {
            try {
                const list = await sercheNft.show(serch);
                if(list.data.status==1) {
                    setNft(list.data.nft); //30 pic
                } else {
                    setNft(0);
                }
            }
            catch(e) {
                console.log('err');
                close();
            }
            finally {
            }
            }
        asy();
    },[]);
    return (   
        <div className="whF">
            <button className="ani1 close pa fs5 z6" style={{right:'15px'}} onClick={close}>X</button>
            {nft==='' ? <Loader />
                : nft!==0 ? (
                    nft.xi==1 ? (
                    <div className='pad15 h100' style={{overflowY: 'auto'}}>
                        <div className='im8l5'>
                            <PlImg url={nft.preview_img} coll={nft.c_col_id} />
                        </div>
                        <div className='tac fs2 vr3h'>
                            <h3 className='fs3 co1'>NFT</h3>
                            {nft.name}
                        </div>
                        <div className='tac fs2 vr3h'>
                            <h3 className='fs3 co1'>{t('collection')}</h3>
                            {nft.c_name}
                        </div>
                        <div className='tac fs2 vr3h'>
                            <button onClick={()=>showNft({'id':nft.nft_id,'col_id':nft.col_id})} className='ani1 btnser btnC1 br fs2'>{t('show')}</button>
                        </div>
                    </div>
                    ) : (
                        <div className='cjd5'>
                            {t('noxinft')}
                            <a target="_blank" href = {`https://mintgarden.io/nfts/${nft.nft_id}`} className="avvd4">-- MintGarden --</a>
                        </div>
                    )
                ) : (
                    <div className='fs3 co5 tac cc pr'>{t('nores')}</div>
                )
            }
        </div>
    );
};

export default Resserch;