import React,{useEffect,useState} from 'react';
import Loader from '../elem/itemnft/loader';
import {paths0} from '../../../API/paths0.js';

import * as THREE from 'three';
import { OrbitControls } from '../../../lib/three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { RGBELoader } from '../../../lib/three/examples/jsm/loaders/RGBELoader.js';

const Viewer = ({parts,chose}) => {
    //console.log(parts);
    /*const [st, setSt] = useState('');
    const [mdv,setMdv] = useState(true);
    const [stLo, setStLo] = useState(false);
    const [comp,setComp] = useState(false);
    const [fullLoad,setFullLoad] = useState(false)
    const [isLoad,setIsLoad] = useState(false);
    const [objFull,setObjFull] =useState('');*/
    const [isLoad,setIsLoad] = useState(false);

useEffect(()=>{
    var rgbeL1,camera, scene = new THREE.Scene(), renderer,controls;
    var loader = new GLTFLoader();
    var group = new THREE.Group();
    var draco = new DRACOLoader();
    const container = document.getElementById('canv12');
    container.style = "opacity:0;";
    //console.log(loader);
    function onWindowResize() {
        camera.aspect = container.offsetWidth / container.offsetHeight;
        camera.updateProjectionMatrix();
        renderer.setSize( container.offsetWidth, container.offsetHeight );
        //render();
    }

    function init() {
        camera = new THREE.PerspectiveCamera( 45, container.offsetWidth / container.offsetHeight, 0.25, 100 );
        //scene = new THREE.Scene();

        renderer = new THREE.WebGLRenderer( { antialias: true } );
        controls = new OrbitControls( camera, renderer.domElement );
        scene.add( group );
        rgbeL1 = new RGBELoader()
        .load( './img/neutral.hdr', function ( texture ) {
            texture.mapping = THREE.EquirectangularReflectionMapping;
            scene.background = new THREE.Color( 0x16272e );
            scene.environment = texture;
            var num = parts.length;
            var nowNum = 0;
            loader.setDRACOLoader( draco.setDecoderPath( 'https://www.gstatic.com/draco/v1/decoders/' ) );
            //console.log(parts);
            parts.map((url)=>{
                loader.load( paths0.local+'models/'+url, function ( gltf ) {
                    group.add( gltf.scene );
                    nowNum++;
                    renderer.render( scene, camera );
                    if(nowNum >= num) {
                        //console.log(group);
                        var box = new THREE.Box3().setFromObject(group);
                        var center = box.getCenter(new THREE.Vector3());
                        controls.target = center;
                        var size = box.getSize(new THREE.Vector3());
                        let max1 = Math.max(size.x,size.y,size.z);
                        let fov = camera.fov;
                        camera.position.set( center.x + size.x, center.y + size.y, center.z + 1.1*max1/2/Math.tan(fov*Math.PI/360) );
                        controls.maxDistance = max1 * 3;
                        controls.minDistance = max1 / 1.5;
                        setIsLoad(true);
                        container.style = "";
                        animate();
                    }
                });
            });
        } );

        renderer.setPixelRatio( window.devicePixelRatio );
        renderer.setSize( window.innerWidth, window.innerHeight );
        renderer.toneMapping = THREE.ACESFilmicToneMapping;
        renderer.toneMappingExposure = 1;
        //renderer.outputEncoding = THREE.sRGBEncoding;
        renderer.domElement.style = 'display: block;touch-action:none;width:100%;height:100%;';
        container.appendChild( renderer.domElement );

        //controls.addEventListener( 'change', render );
        controls.minDistance = 2;
        controls.maxDistance = 10;
        controls.target.set( 0, 0, 0 );
        controls.autoRotate = true;
        //controls.update();
        window.addEventListener( 'resize', onWindowResize );
    }
    function animate() {
        if(controls) {
            requestAnimationFrame( animate );
            controls.update();
            renderer.render( scene, camera );
        }
    }
    init();

    return ()=> {
async function disposeAll(obj,ip=0,ip2=0) {
        function d_dis(ch) {
            ch.removeFromParent();
        if (ch.geometry) {
            ch.geometry.dispose();
            ch.geometry = null;
        }     
        if (ch.material) {
            ip2++;
            if (ch.material.map) {
                ip++;
                ch.material.map.dispose();
                ch.material.map = null;
            }
            if (ch.material.lightMap) {
                ip++;
                ch.material.lightMap.dispose();
                ch.material.lightMap = null;
            }
            if (ch.material.bumpMap) {
                ip++;
                ch.material.bumpMap.dispose();
                 ch.material.bumpMap = null;
            }
            if (ch.material.normalMap) {
                ip++;
                ch.material.normalMap.dispose();
                ch.material.normalMap = null;
            }
            if (ch.material.specularMap) {
                ip++;
                ch.material.specularMap.dispose();
                ch.material.specularMap = null;
            }
            if (ch.material.specularColorMap) {
                ip++;
                ch.material.specularColorMap.dispose();
                ch.material.specularColorMap = null;
            }
            if (ch.material.envMap) {
                ip++;
                ch.material.envMap.dispose();
                ch.material.envMap = null;
            }
            if (ch.material.emissiveMap) {
                ip++;
                ch.material.emissiveMap.dispose();
                ch.material.emissiveMap = null;
            }
            if (ch.material.metalnessMap) {
                ip++;
                ch.material.metalnessMap.dispose();
                ch.material.metalnessMap = null;
            }
            if (ch.material.roughnessMap) {
                ip++;
                ch.material.roughnessMap.dispose();
                ch.material.roughnessMap = null;
            }    
            ch.material.dispose();
            ch.material = null;
        }
        }    
        function d_sechCh(ch) {
            for (let i=ch.children.length-1; i>=0 ;i--) {
                    d_sechCh(ch.children[i]);
                    d_dis(ch.children[i]);
            }                
        }
        try {
        await d_sechCh(obj);
        }
        finally {
            window.removeEventListener( 'resize', onWindowResize );
            group=null;
            controls.dispose();
            controls=null;
            loader=null;
            draco.dispose();
            draco = null;
            rgbeL1.dispose();
            rgbeL1 = null;
            scene.environment=null;
            scene.source = null;

            scene=undefined;
            renderer.forceContextLoss();
            renderer.dispose();
            renderer=undefined;
        }
    }
        disposeAll(group);
    }  
},[]);

return (
    <div id='canDi' className="l1d pa whF lt0 op0">
        <div id = 'canv12' className='whF pr' />
            {isLoad===false && 
                <div className='pa tac cc'>
                    <p>Loading parts</p>
                    <div className='loadBar br pr'>
                        <Loader />
                    </div>
                </div>
            }
    </div>    
)
};

export default Viewer;