import React,{useState,useRef} from 'react';
import { useTranslation } from 'react-i18next';
import { Transition } from 'react-transition-group';
import voi from '../../../images/voi.png';
import xch from '../../../images/coins/xch.png';
import xivi from '../../../images/coins/xivi.png';

import AddOd from './offer/AddOd'


const BtnAp = (pr) => {
    const { t } = useTranslation();
    const [isOpen,setIsOpen]=useState(false);
    const ref = useRef(null);
    return (
        <div>
            <button className="btn1 hbtn2 ani1" onClick={()=>setIsOpen(true)}>
                <div className="btnL">{pr.txt}</div>
                <div className="btnR">+</div>
            </button>
            {
                <Transition nodeRef={ref} unmountOnExit={true} in={isOpen} timeout={300} onEntered={()=>{
                    //document.addEventListener('click', _lMenuEvent,false);
                }} onExited={()=>{
                    // document.removeEventListener('click', _lMenuEvent, false);
                }}>
                {state => (       
                    <div ref={ref} className={`ani1 bgB lt0 upS-${state}`}>
                        <div className='box1 pa cc'>
                            <button className='btnx1 ani1 fs5' style={{zIndex:1004}} onClick={()=>setIsOpen(false)}>X</button>
                            <div className="add_warr df jcc tal">
                            <div className="lt">
                                    <img src={voi} />
                            </div>
                                <div>
                                    <span className="fs2">{t('onlyXi1')}<br />{t('onlyXi2')}</span>
                                    <div className="coins_v df jcl">
                                        <div className="uul df jcl">
                                            <img src={xch} />
                                            <span>XCH</span>
                                        </div>
                                        <div className="uul df jcl">
                                            <img src={xivi} />
                                            <span>XIVI</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <AddOd />
                        </div>
                    </div>
                )}
                </Transition>
            }
        </div>
    );
};

export default BtnAp;