import React, { useState } from 'react';
import {BrowserRouter} from "react-router-dom";
import './styles/style.css';
import Header from "./components/header/header";
import PageRouter from "./components/PageRouter";
import SochDiv from "./components/sochial/SochDiv";

function App() {
  return (
    <BrowserRouter>
      <div className = "wr1">
        <Header/>
        <SochDiv />
        <PageRouter />
      </div>
    </BrowserRouter>
  );
}

export default App;
