import React,{useState,useRef} from 'react';
import { useTranslation } from 'react-i18next';
import {stInfo} from '../../../../API/startinfo';
import SelectTp from './btn/selectTp';

const SelectRow = ({chos, chose,sele}) => {
    const { t } = useTranslation();
    return (
        <div>
            {Object.keys(stInfo[chose]).map((id)=>(
                stInfo[chose][id]['parent']===0 && <SelectTp key={`cho_${chose}_${id}`} chos={chos} chose={chose} idn={id}  sele={sele} />   
            ))}
        </div>
    );
};

export default SelectRow;