import axios from "axios";
import {paths0} from "./paths0.js";

export default class sercheNft {
    static async show(nft) {
        //console.log(coll);
        var bod = {
            nft_id:nft
        };
        try {
        const response = await axios.post(paths0.serv+'serch_for_id.php', bod);
        //console.log(response);
          return response;
        } catch(err) {
            console.log(err);
        }
    }
}