import axios from "axios";
import {paths0} from "./paths0.js";

export default class Offer {
    static async add(offer1) {
        try {
            //console.log('Aproved');
            //console.log(offer1);
        const response = await axios.post(paths0.serv+'addnewoffer.php', {
            offer:offer1,
        })
        return response;
        } catch(err) {
            console.log(err);
        }
    }
}