import React, { useEffect } from 'react';
import './style.css';
import RateXch from '../../../../API/rateXch.js';
import {useMaSelector,useMaUpdate} from  '../../../../context/marketCont';
import xchI from '../../../../images/coins/xch.png';
import xivi from '../../../../images/coins/xivi.png';

const Rates = () => {
    const updateValue = useMaUpdate();
    async function _upRate() {
        try{
            const res = await RateXch.show();
            updateValue({value: res.data.usd})
        }
        catch(e) {
            console.log(e);
        } 
    }
    const price = useMaSelector((state) => state.value);
    useEffect(()=>{
            _upRate();
    },[]);
    return (
        <div className="wr_rate">
        <div className="d_rate">
            <div className="tr">
                <div className="td l">
                    <div className="fle">
                        <img src={xchI} />
                        XCH
                    </div>
                </div>
                <div className="td r">{price}$</div>
            </div>
            <div className="tr">
                <div className="td l">
                    <div className="fle">
                        <img src={xivi} />
                        XIVI
                    </div>
                </div>
                <div className="td r">---</div>
            </div>
        </div>
        <button className="rel" onClick={()=>{           
                _upRate();
            }}><i className="faRe ani1 fa fa-refresh" aria-hidden="true"></i></button>
        </div>
    );
};

export default Rates;