import axios from "axios";
import {paths0} from "./paths0.js";

export default class ListNftSale {
    static async show(pg,type,of,req,atr ) {
        var bod,url;
        if(type==1) {
            if(req.coin) {
                var side_nft = 0, coll_id = Number(of[3].id), type_coll = Number(of[2].id), coin_id = Number(req.coin.id);
            }
            else {
                var side_nft = 1, coll_id = Number(req[3].id), type_coll = Number(req[2].id), coin_id = Number(of.coin.id);
            }
            bod = {
                page:pg,
                coll_id:coll_id,
                type_coll:type_coll,
                coin_id:coin_id,
                side_nft:side_nft,
                atr:atr
            };
            url = Object.keys(atr).length > 0 ? 'list_1_NftCoinAtr' : 'list_1_NftCoin'
        }
        if(type==2) {
            bod = {
                page:pg,
                of_type:of[2].id,
                of_col:of[3].id,
                req_type:req[2].id,
                req_col:req[3].id
            };
            url = 'listNftNft';
        }
        if(type==3) {
            bod = {
                page:pg
            };
            url = 'listmulti';
        }
        try {
            //console.log(of);
            //console.log(req);
            
        const response = await axios.post(paths0.serv+url+'.php', bod);
        return response;
        } catch(err) {
            console.log(err);
        }
    }
}