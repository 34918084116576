import React,{useState} from 'react';
import { useTranslation } from 'react-i18next';

const BtnSelect3 = ({val,act}) => {
    const { t } = useTranslation();

return (
    <button className='ani1 df jcc btnCho pad5 br' onClick={()=>{act(true)}}>
        {val!=='' && <div className='forimg1'><img src={val.img} /></div>}
        <div className='co2 fs1'>{val==='' ? t('select') : val.name}</div>
    </button>
);
};

export default BtnSelect3;