import React,{useEffect,useState,useRef} from 'react';
import Nftcartoff from './Nftcartoff';
import CoinCartOff from './CoinCartOff';
import {_generStr,_load,_copy,_accW} from '../../../func/ActOffer.js';
import { useTranslation } from 'react-i18next';
import imgGoby from '../../../../src/images/gobyLogo.svg';
import Loader from '../elem/itemnft/loader.jsx';

const WalList = ({id,closex}) => {
    const [ok, setOk] = useState(false);
    const [res,setRes] = useState('');
    const { t } = useTranslation();
    //console.log(id);
    const aprove = (id1) => {
        setOk(true);
        const asy = async()=> {
            try {
                const apr = await _accW.take(id1);
                //console.log(apr);
                if(apr===1) {
                    setRes('Confirmed');
                }
                if(apr===2) {
                    setRes(t('try_later'));
                }
            }
            catch(e) {
                console.log('err');
            }
            finally {
                setOk(false);
            }
        }
        asy();  
    }
    const { chia } = window;
    return (
        <div className='whF pa lt0 d4g sh1'>
            <button onClick={()=>closex('')} className='ani1 close pa fs5 z6' style={{right: '10px'}}>X</button>
            {ok===false ? res==='' ? 
            (<div className='pa cc tac' style={{width: '320px',maxWidth:'100%'}}>
                <h3 className='fs5 co2 h2n8'>{t('selWal')}</h3>
                <button onClick={()=>{Boolean(chia && chia.isGoby)===true ? aprove(id) : window.open('https://goby.app') }} className='ani1 w100 pad5 btn3nf'>
                    <div className='df jcc w100'>
                        <img className='wv3' src={imgGoby} />
                        <span className='fs3'>Goby Wallet</span>
                    </div>
                    {Boolean(chia && chia.isGoby)===false && <span className='fs1' style={{color: '#ff5252'}}>{t('gobyno')}</span>}                 
                </button>
            </div>) : (<div className='pa cc fs4'>{res}<button onClick={()=>closex('')} className='ani1 btn8h3'>OK</button></div>) : <Loader />
            }
        </div>
    )
};

export default WalList;