import ic_1 from '../images/collections/icol1jyasmhd4dl9caxuqyrmjnkzcq4fjpjhc0ry9kdqse0xvrkqv6dqsj9er9p.webp';
import ic_2 from '../images/collections/iconTypeA_1.webp';
import ic_3 from '../images/collections/iconTypeB_1.webp';
import ic_4 from '../images/collections/iconTypeC_1.webp';
import ic_5 from '../images/collections/iconTypeD_1.webp';
import ic_6 from '../images/collections/iconTypeE_1.webp';
import ic_7 from '../images/collections/iconTypeE_2.webp';
import ic_8 from '../images/collections/iconTypeE_3.webp';
import ic_9 from '../images/collections/iconTypeF_1.webp';

import ba_1 from '../images/collections/bcol1jyasmhd4dl9caxuqyrmjnkzcq4fjpjhc0ry9kdqse0xvrkqv6dqsj9er9p.webp';
import ba_2 from '../images/collections/b_typeA_1.jpg';
import ba_3 from '../images/collections/b_typeB_1.jpg';
import ba_4 from '../images/collections/b_typeC_1.jpg';
import ba_5 from '../images/collections/b_typeD_1.jpg';
import ba_6 from '../images/collections/b_typeE_1.jpg';
import ba_7 from '../images/collections/b_typeE_2.jpg';
import ba_8 from '../images/collections/b_typeE_3.jpg';
import ba_9 from '../images/collections/b_typeF_1.jpg';
import xch from '../images/coins/xch.png';
import xivi from '../images/coins/xivi.png';
export const stInfo = {
1: {
    1:{'name':'Xivion Farm Game','cat':14,'parent':14}
},
2:{
    2:{'name':{"en":"Сombat Cars","cn":"Сombat Cars","ru":"Боевые Авто"},'proj':1, 'parent':0},
    3:{'name':{"en":"Weapons","cn":"Weapons","ru":"Оружие"},'proj':1, 'parent':0},
    4:{'name':{"en":"Type A","ru":"Тип А"},'proj':1, 'parent':3},
    5:{'name':{"en":"Type B","ru":"Тип B"},'proj':1, 'parent':3},
    6:{'name':{"en":"Type C","ru":"Тип C"},'proj':1, 'parent':3},
    7:{'name':{"en":"Type D","ru":"Тип D"},'proj':1, 'parent':3},
    8:{'name':{"en":"Upgrades","cn":"","ru":"Улучшения"},'proj':1, 'parent':0},
    9:{'name':{"en":"Type E","ru":"Тип E"},'proj':1, 'parent':8},
    10:{'name':{"en":"Type F","ru":"Тип F"},'proj':1, 'parent':8}
},
3:{
    1:  {
        "coll_id":"col1jyasmhd4dl9caxuqyrmjnkzcq4fjpjhc0ry9kdqse0xvrkqv6dqsj9er9p",
        "did":"did:chia:16mr0wnln7gu7qwmj6fr65sarc4qhcdhzk2hp7qrgv9r3ccj90etqs2gzp6",
        "hex_did":"d6c6f74ff3f239e03b72d247aa43a3c5417c36e2b2ae1f006861471c62457e56",
        "name":"Xivion LEGENDARY - Farm NFT Collection",
        "description":"Total 1200 unique NFTs in this collection. All NFTs from this collection is used in the Xivion Farm Game to farm the main Xivion Project token, as well as to mine all the resources that will be available in the Game!",
        "icon":ic_1,
        "banner":ba_1,
        "social": {
            "website":"https://xivion.io/",
            "x":"@xivion_crypto"
        },
        "category":14,
        "project":1,
        "typenft":[2],
        "parent":0,
        "atr": {
                1:["78","82","86","90"],2:["140","150"],3:["130","135","140","145","150"],4:["320","340","360","380","400"],5:["60","90"],6:["10000","15000"],7:["1","2","3"],8:["300","600","900"]
        }    
        },
    2:
        {
        "coll_id":"col1ha656j3xhumrkdzfeawepe74mjmyqja0t4nm9cf6gkc7hva82slqdejhsp",
        "did":"did:chia:16mr0wnln7gu7qwmj6fr65sarc4qhcdhzk2hp7qrgv9r3ccj90etqs2gzp6",
        "hex_did":"d6c6f74ff3f239e03b72d247aa43a3c5417c36e2b2ae1f006861471c62457e56",
        "name":"Star HyperBlaster - Xivion Weapon Collection",
        "description":"Type A",
        "icon":ic_2,
        "banner":ba_2,
        "social": {
            "website":"https://xivion.io/",
            "x":"@xivion_crypto"
        },
        "category":14,
        "project":1,
        "typenft":[3,4],
        "parent":2,
        "atr": {
                9:['12'],10:["2000"]
        }    
    },
    3:  {
        "coll_id":"col12uzxf56wktpu8n2lvfs8n7q5zz2ma2fz8x53lva4we2s5a6v720qqxa58n",
        "did":"did:chia:16mr0wnln7gu7qwmj6fr65sarc4qhcdhzk2hp7qrgv9r3ccj90etqs2gzp6",
        "hex_did":"d6c6f74ff3f239e03b72d247aa43a3c5417c36e2b2ae1f006861471c62457e56",
        "name":"Sky Striker - Xivion Weapon Collection",
        "description":"Type B",
        "icon":ic_3,
        "banner":ba_3,
        "social": {
            "website":"https://xivion.io/",
            "x":"@xivion_crypto"
        },
        "category":14,
        "project":1,
        "typenft":[3,5],
        "parent":2,
        "atr": {
            9:['15'],10:["3000"]
        }    
    },
    4:  {
        "coll_id":"col1znk66fpp7wmmndkmun4er34gzqu7z6nmd928yw3rz3ctdny9lsmsq69v7x",
        "did":"did:chia:16mr0wnln7gu7qwmj6fr65sarc4qhcdhzk2hp7qrgv9r3ccj90etqs2gzp6",
        "hex_did":"d6c6f74ff3f239e03b72d247aa43a3c5417c36e2b2ae1f006861471c62457e56",
        "name":"EMP Spark - Xivion Weapon Collection",
        "description":"Type C",
        "icon":ic_4,
        "banner":ba_4,
        "social": {
            "website":"https://xivion.io/",
            "x":"@xivion_crypto"
        },
        "category":14,
        "project":1,
        "typenft":[3,6],
        "parent":2,
        "atr": {
            9:["11"],10:["8000"],11:['3000']
        }    
    },
    5:  {
        "coll_id":"col1mepr6th48ukxjvvny9rxee7xadddvegx7emgdh4ya3jmdme2s9asmy4hd2",
        "did":"did:chia:16mr0wnln7gu7qwmj6fr65sarc4qhcdhzk2hp7qrgv9r3ccj90etqs2gzp6",
        "hex_did":"d6c6f74ff3f239e03b72d247aa43a3c5417c36e2b2ae1f006861471c62457e56",
        "name":"Explosive Seeding - Xivion Weapon Collection",
        "description":"Type D",
        "icon":ic_5,
        "banner":ba_5,
        "social": {
            "website":"https://xivion.io/",
            "x":"@xivion_crypto"
        },
        "category":14,
        "project":1,
        "typenft":[3,7],
        "parent":2,
        "atr": {
            9:["7"],10:["1000"]
        }    
    },
    6:  {
        "coll_id":"col1zu5enjn7p6ucf6en3a4ly9pmn3h68fhhh8k5ayl4nftwrwc6upjqh9sr9h",
        "did":"did:chia:16mr0wnln7gu7qwmj6fr65sarc4qhcdhzk2hp7qrgv9r3ccj90etqs2gzp6",
        "hex_did":"d6c6f74ff3f239e03b72d247aa43a3c5417c36e2b2ae1f006861471c62457e56",
        "name":"Assault Ampoule - Xivion Upgrade Collection",
        "description":"Type E",
        "icon":ic_6,
        "banner":ba_6,
        "social": {
            "website":"https://xivion.io/",
            "x":"@xivion_crypto"
        },
        "category":14,
        "project":1,
        "typenft":[8,9],
        "parent":2,
        "atr": {
            3:["+10%"]
        }    
    },
    7:  {
        "coll_id":"col1rd3k398753dsltyr4etfspt8p9wkskumxcuv2x2adqahr0jeevpqxc7tfu",
        "did":"did:chia:16mr0wnln7gu7qwmj6fr65sarc4qhcdhzk2hp7qrgv9r3ccj90etqs2gzp6",
        "hex_did":"d6c6f74ff3f239e03b72d247aa43a3c5417c36e2b2ae1f006861471c62457e56",
        "name":"Vitality Cask - Xivion Upgrade Collection",
        "description":"Type E 2",
        "icon":ic_7,
        "banner":ba_7,
        "social": {
            "website":"https://xivion.io/",
            "x":"@xivion_crypto"
        },
        "category":14,
        "project":1,
        "typenft":[8,9],
        "parent":2,
        "atr": {
            4:["+7%"]
        }    
    },
    8:  {
        "coll_id":"col1f7uklqfxswlms5zy6z57qnzvv0jw302a2fm8mrxsl3dal7d0wzgsrdrq76",
        "did":"did:chia:16mr0wnln7gu7qwmj6fr65sarc4qhcdhzk2hp7qrgv9r3ccj90etqs2gzp6",
        "hex_did":"d6c6f74ff3f239e03b72d247aa43a3c5417c36e2b2ae1f006861471c62457e56",
        "name":"Speed Surge - Xivion Upgrade Collection",
        "description":"Type E 3",
        "icon":ic_8,
        "banner":ba_8,
        "social": {
            "website":"https://xivion.io/",
            "x":"@xivion_crypto"
        },
        "category":14,
        "project":1,
        "typenft":[8,9],
        "parent":2,
        "atr": {
            1:["+5%"]
        }    
    },
    9:  {
        "coll_id":"col1p7gjk666ckn3jcdale2hfll47wm3m2cplpchu62cnyj0y3p8vrrqml8sa7",
        "did":"did:chia:16mr0wnln7gu7qwmj6fr65sarc4qhcdhzk2hp7qrgv9r3ccj90etqs2gzp6",
        "hex_did":"d6c6f74ff3f239e03b72d247aa43a3c5417c36e2b2ae1f006861471c62457e56",
        "name":"Rapid Runner - Xivion Upgrade Collection",
        "description":"Type F",
        "icon":ic_9,
        "banner":ba_9,
        "social": {
            "website":"https://xivion.io/",
            "x":"@xivion_crypto"
        },
        "category":14,
        "project":1,
        "typenft":[8,10],
        "parent":2,
        "atr": {
            1:["+10%"]
        }    
    }
}
}

export const CoinsStInfo = {

1: {
    "asset_id":"xch",
    "name":"XCH",
    "img": xch
},
2: {
    "asset_id":"940613a8af071d5ad8feb65e1eef5aaa3813e5091988734c75689cb2dd1c272b",
    "name":"XIVI",
    "img": xivi
}
}

export const AtrStInfo = {
    1:{
        "en":"Speed",
        "cn":"速度",
        "ru":"Скорость"
    },
    2:{
        "en":"Armor",
        "cn":"护甲",
        "ru":"Защита"
    },
    3:{
        "en":"Attack",
        "cn":"攻击",
        "ru":"Атака"
    },
    4:{
        "en":"HP",
        "cn":"HP",
        "ru":"HP"
    },
    5:{
        "en":"SPECIAL WEAPON - Damage",
        "cn":"特殊武器 - 伤害",
        "ru":"СПЕЦИАЛЬНОЕ ОРУЖИЕ - Урон"
    },
    6:{
        "en":"SPECIAL WEAPON - Reloading",
        "cn":"特殊武器 - 重新加载",
        "ru":"СПЕЦИАЛЬНОЕ ОРУЖИЕ - Перезарядка"
    },
    7:{
        "en":"STANDARD WEAPON - Damage",
        "cn":"标准武器 - 伤害",
        "ru":"ШТАТНОЕ ОРУЖИЕ - Урон"
    },
    8:{
        "en":"STANDARD WEAPON - Reloading",
        "cn":"标准武器 - 重新加载",
        "ru":"ШТАТНОЕ ОРУЖИЕ - Перезарядка"
    },
    9:{
        "en":"Damage",
        "cn":"Damage",
        "ru":"Урон"
    },
    10:{
        "en":"Reloading time(ms)",
        "cn":"Reloading time(ms)",
        "ru":"Время перезарядки(мс)"
    },
    11:{
        "en":"EMP(ms)",
        "cn":"EMP(ms)",
        "ru":"ЭМИ(мс)",
    }
}

export const ProjStInfo = {
    1:"Xivion Farm Game"
}

export const TpnftStInfo = {
    1:{'txt':{"en":"Сombat Cars","cn":"Сombat Cars","ru":"Боевые Авто"},'proj':1},
    2:{'txt':{"en":"Weapons","cn":"Weapons","ru":"Оружие"},'proj':1},
    3:{'txt':{"en":"Upgrades","cn":"","ru":"Улучшения"},'proj':1}
}