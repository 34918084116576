import React,{useState,useRef} from 'react';
import { useTranslation } from 'react-i18next';
import { Transition } from 'react-transition-group';
import Resserch from './Resserch';
import './style.css';


const SearchL = ({showNft}) => {
    const { t } = useTranslation();
    const idn = useRef(null);
    const ref2 = useRef(null);
    const [isOpen,setOpen] = useState(false);
    const [ser,setSer] = useState(false);

    const CloseAct = ()=>{
        if(isOpen===true){setOpen(false)}
        document.removeEventListener('click', _lMenuEvent, false);
    }
    const _lMenuEvent =ee=> {
        if(idn.current) {
            if (!idn.current.contains(ee.target)) {
                CloseAct();
            }
        }
    }
    const hand = () => {
        let val = ref2.current.value;
        if(val.length === 62 && val.substr(0, 3)==='nft') {
            //console.log(val);
            setSer(val);
            setOpen(true);
        }
    }
    return (   
        <div className="d_inp">
            <div className='df'>
                <input ref={ref2} className="inp w100 pa1 ma1" placeholder={t('searchtxt')} />
                <button onClick={hand} className='ani1 iis'><i className="fs2 fa fa-search" aria-hidden="true"></i></button>
            </div>
            <Transition nodeRef={idn} unmountOnExit={true} in={isOpen} timeout={300} onEntered={()=>{
            document.addEventListener('click', _lMenuEvent,false);
        }} >
            {state => (
                <div ref={idn} className={`ani1 dv_wr dv-${state}`}>
                    <Resserch serch={ser} close={CloseAct} showNft={showNft} />
                </div>
            )}
      </Transition>
        </div>
    );
};

export default SearchL;