import React,{useState,useRef,useContext} from 'react';
import { useTranslation } from 'react-i18next';
import Choise from '../buttons/choise/choise';
import ChoiseCoin from '../buttons/choise/choiseCoin';
import {useSerchCSelector,useSerchCUpdate} from '../../../context/serchC.js';

import './style.css';

const Mod1 = ({closeAct}) => { 
    const { t } = useTranslation();
    const modestr = useSerchCSelector((state) => state.value);

//console.log(Object.keys(modestr.of).length);
const _clear = ()=>{
    return {
        'type':1,
        'of':{
            2:{'name':t('typenft'),'id':0,'targ':2,'list':[]},
            3:{'name':t('collection'),'id':0,'targ':3,'list':[]}
        },
        'req':{
            'coin':{'name':'Coin','id':0}
        },
        'atr':{
        }
    }
}

    var inner1 = modestr.type != 1 ? inner1 = _clear() : inner1 = JSON.parse(JSON.stringify(modestr))

    const [forSelect,setForSelect] =useState(inner1);
    //console.log(forSelect);
    const _ch_side = () => {
        let of = JSON.parse(JSON.stringify(forSelect.req));
        let req = JSON.parse(JSON.stringify(forSelect.of));
        let atr = JSON.parse(JSON.stringify(forSelect.atr));
        let inner2 = {
            'type':1,
            'of':of,
            'req':req,
            'atr':atr
        };
        setForSelect(inner2);
    }
    const updSerch = useSerchCUpdate();
    const stSerch=(pr)=>{
        closeAct();
        const d1 = document.getElementById('lfBar');
        d1.style = "";
        document.getElementById('mrl1').classList.remove('mrlA1');
        document.getElementById('mrl2').classList.remove('mrlA2');
        document.getElementById('mrl3').classList.remove('mrlA3');
        document.getElementById('mrl3').classList.add('mrlz3');
        JSON.stringify(modestr)!==JSON.stringify(pr) && updSerch({value: pr})
        //modestr != pr && updSerch({value: pr})
    }

    return (        
        <div className="h100">
            <button className="ani1 close pa fs5 z6" style={{right:'15px'}} onClick={closeAct}>X</button>
            <div className="dv df jcc h100 ofa">
            {!forSelect.of.coin ? <Choise key="snf3" chos='of' forSelect={forSelect} setForSelect={setForSelect} /> 
                                 : <ChoiseCoin key="snf6" chos='of' forSelect={forSelect} setForSelect={setForSelect} />
            }
                <button onClick={_ch_side} className="ch_arrow"><i className="fs5 fa fa-long-arrow-right" aria-hidden="true"></i><br /><i className="fs5 fa fa-long-arrow-left" aria-hidden="true"></i></button>
            {!forSelect.req.coin ? <Choise key="snf4" chos='req' forSelect={forSelect} setForSelect={setForSelect} /> 
                                 : <ChoiseCoin key="snf7" chos='req' forSelect={forSelect} setForSelect={setForSelect} />
            }   
            </div>
            <div className='pa cl z6 b10 jkd4'>
                <button className="ani1 btnser btnC1 br fs2" onClick={()=>stSerch(forSelect)}>{t('search')}</button>
                <button className="ani1 btnser btnC3 br fs2" onClick={()=>setForSelect(_clear())}>{t('clear')}</button>
            </div>
        </div>

    );
};

export default Mod1;