import React,{useState,useRef,useContext} from 'react';
import { useTranslation } from 'react-i18next';
import Choise from '../buttons/choise/choise.jsx';
import {useSerchCSelector,useSerchCUpdate} from '../../../context/serchC.js';

import './style.css';

const Mod2 = ({targ,closeAct}) => { 
    const { t } = useTranslation();
    const modestr = useSerchCSelector((state) => state.value);

    const _clear = ()=>{
        return {
            'type':2,
            'of':{
                2:{'name':t('typenft'),'id':0,'targ':2,'list':[]},
                3:{'name':t('collection'),'id':0,'targ':3,'list':[]}
            },
            'req':{
                2:{'name':t('typenft'),'id':0,'targ':2,'list':[]},
                3:{'name':t('collection'),'id':0,'targ':3,'list':[]}
            }
        }
    }
    var inner1 = modestr.type != 2 ? inner1 = _clear() : inner1 = JSON.parse(JSON.stringify(modestr))
    const [forSelect,setForSelect] =useState(inner1);

    const _ch_side = () => {
        let of = JSON.parse(JSON.stringify(forSelect.req));
        let req = JSON.parse(JSON.stringify(forSelect.of));
        let inner2 = {
            'type':2,
            'of':of,
            'req':req,
        };
        setForSelect(inner2);
    }
    const updSerch = useSerchCUpdate();
    const stSerch=(pr)=>{
        closeAct();
        const d1 = document.getElementById('lfBar');
        d1.style = "";
        document.getElementById('mrl1').classList.remove('mrlA1');
        document.getElementById('mrl2').classList.remove('mrlA2');
        document.getElementById('mrl3').classList.remove('mrlA3');
        document.getElementById('mrl3').classList.add('mrlz3');
        updSerch({value: pr});
    }
    const onSubmit = e => {
        e.preventDefault();
    }

    return (
        
        <div className="h100">
            <button className="ani1 close pa fs5 z6" style={{right:'15px'}} onClick={closeAct}>X</button>
            <div className="dv df jcc h100 ofa">
                <Choise key="snf1" chos='of' forSelect={forSelect} setForSelect={setForSelect} />
                <button onClick={_ch_side} className="ch_arrow"><i className="fs5 fa fa-long-arrow-right" aria-hidden="true"></i><br /><i className="fs5 fa fa-long-arrow-left" aria-hidden="true"></i></button>
                <Choise key="snf2" chos='req' forSelect={forSelect} setForSelect={setForSelect} />
            </div>
            
            <div className='pa cl z6 b10 jkd4'>
                <button className="ani1 btnser btnC1 br fs2" onClick={()=>stSerch(forSelect)}>{t('search')}</button>
                <button className="ani1 btnser btnC3 br fs2" onClick={()=>setForSelect(_clear())} >{t('clear')}</button>
            </div>

        </div>

    );
};

export default Mod2;