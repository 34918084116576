import React,{useEffect,useState,useRef} from 'react';
//import * as THREE from 'three'; 
import {Transition} from 'react-transition-group';
import { useTranslation } from 'react-i18next';
import Loader from '../elem/itemnft/loader';
import AboutNft from '../../../API/aboutNft';
import {stInfo,AtrStInfo} from '../../../API/startinfo';

const InfNftA = ({nftInf}) => {
    const { t } = useTranslation();
    const atrS = () => {
        var arrAtr = {};
        Object.keys(nftInf).map((key)=>{
            if(key.substring(0, 3)==='atr') {
                let subkey = key.substring(3);
                arrAtr[AtrStInfo[subkey]['en']]= nftInf[key];
            }
        });
        return arrAtr;
    }
    const atrOn= atrS();
    return (
    <div className='dRnft'>
        <div className='abN'>
            <h3 className='h31'>{t('project')}</h3>
                <div>
                    Xivion Farm Game
                </div>
            </div>
        <div className='abN'>
            <h3 className='h31'>{t('description')} NFT</h3>
            <div className='df jcc'>
                {nftInf.descri}
            </div>
        </div>
        <div className='abN'>
            <h3 className='h31'>{t('collection')}</h3>
            <div className='df jcc'>
                <img className='icColl' src={stInfo[3][nftInf.col_id].icon} />
                <p className='pColl fs3'>{stInfo[3][nftInf.col_id].name}<br /><a target="_blank" className='fs2 co2' href={`https://mintgarden.io/collections/${stInfo[3][nftInf.col_id]['coll_id']}`}>{t('view_on')} MintGarden</a></p>
            </div>
        </div>
        <div className='abN'>
            <h3 className='h31'>{t('attributes')}</h3>
                <div>
                {Object.keys(atrOn).map((name)=>(
                    <div key = {`xa_${name}`} className='tac d63i'>
                        <span className='spN w100 fs3'>{name}</span>
                        <span className='fs2'>{atrOn[name]}</span>
                    </div>
                ))

                }
                </div>
        </div>
    </div>
        )
};

export default InfNftA;