import React,{useState} from 'react';
import BtnLm from '../btnlm/btnLm.jsx';
import BtnMu from '../btnlm/BtnMu.jsx';

import {useSerchCSelector} from '../../../../context/serchC.js';
//import {Filt} from '../../../../context/filt.js';

const FiltD = () => {
    const modestr = useSerchCSelector((state) => state.value);
    return (
            <div>
                <BtnLm key="k1k" txt="Coin" onc="lme_1" act={1} down={0} cssc={modestr.type===1 ? 'actCol' : ''}/>
                <BtnLm key="k2k" txt="NFT" onc="lme_2" act={2} down={0} cssc={modestr.type===2 ? 'actCol' : ''}/>
                <BtnMu key="k3k" onc="lme_3" cssc={modestr.type===3 ? 'actCol' : ''}/>
            </div>
    );
};

export default FiltD;