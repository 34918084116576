import axios from "axios";
import {paths0} from "./paths0.js";

export default class OffersNft {
    static async show(nft,coll) {
        var bod = {
            id_nft:nft,
            id_col:coll
        };
        try {
        //const response = await axios.post('http://garagev2/act/garage/offersList.php', bod);
        const response = await axios.post(paths0.serv+'offersList.php', bod);
        //console.log(response);
          return response;
        } catch(err) {
            console.log(err);
        }
    }
}