import React,{useState,useRef} from 'react';
import { useTranslation } from 'react-i18next';
import Offer from '../../../../API/Offer';


const BdRow = ({refa,setUpLoad,setResLoad,cl}) => {
    const { t } = useTranslation();
    const [errRow,setErrRow] = useState('');
    const textRef = useRef(null);

    const _send = ()=> {
        var val = textRef.current.value.trim();
        if(val.substr(0,5)==='offer' && val.length >20 && val.indexOf(' ')===-1) {
            setUpLoad(true);
            const asy = async()=> {
                try {
                    const list = await Offer.add(val);
                    setResLoad(list.data.status);
                }
                catch(e) {
                    console.log('err');
                    setResLoad(0);
                }
                finally {
                    textRef.current.value = "";
                    setErrRow('');
                }
            }
            asy();

        } else {
            setErrRow(t('invalRow'));
        }
    }
    return (
        <div ref={refa} className={`ani1 pa wd1 cc ${cl}`}>
            <p className='fs3 co5'>{errRow}</p>
            <textarea ref={textRef} className="add_txar" placeholder={t('raw_of_here')}></textarea>
            <button onClick={_send} className="btno2 ani1">{t('sub_of')}</button>
        </div>

    );
};

export default BdRow;