import React,{useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import {Airinf} from '../API/Airinf.js';

const Airdrops = () => {
    useEffect(()=>{
        const d1=document.getElementById('navj7');
        if(d1.style.right != '') {
            d1.style = "";
            document.getElementById('mrl11').classList.remove('mrlA1');
            document.getElementById('mrl22').classList.remove('mrlA2');
            document.getElementById('mrl33').classList.remove('mrlA3');
        }
    },[])
    const { t } = useTranslation();

    return (
        <div className="page1">
            <div className="bar1 ani1" id="lfBar">
                <div className="df"><h1 className="h11">Airdrops</h1><div className="h11_l"></div></div>
                <button className="btn1 hbtn1 ani1 cn">
                    <div className="btnL">
                        <div className="n">{t('soon')}</div>
                    </div>
                    <div className="btnR">
                        <div className = 'actCol c ani1'></div>
                        <i className="ar_R ani1 fa fa-caret-right" aria-hidden="true"></i>
                    </div>
                </button>
            </div>
            <div className="bar2">
            {Object.keys(Airinf).map((n)=>(
                <div key={'airn'+n} className='airit df'>
                    <div className='airl'><div className='brfd pad5'><img className='whma1 pr' src={Airinf[n].img} /></div></div>
                    <div className='airr'>
                        <div className='airrow fs2'><h4 className='co1'>{t('project')}</h4> Xivion Farm Game</div>
                        <div className='airrow fs2'><h4 className='co1'>{t('airdrop')}</h4> {Airinf[n].air}</div>
                        <div className='airrow fs2'><h4 className='co1'>{t('action')}</h4> {Airinf[n].act}</div>
                        <div className='airrow fs2'><h4 className='co1'>{t('status')}</h4> Coming Soon</div>
                        <button className='cn ani1 btnser btn9j3 br fs2'>{t('details')}</button>
                    </div>
                </div>
            ))
            }
            </div>
        </div>
    );
};

export default Airdrops;