import React,{useState,useRef} from 'react';
import { useTranslation } from 'react-i18next';
import { Transition } from 'react-transition-group';

import Loader from '../../elem/itemnft/loader';
import ResAddOff from './resAddOff';
import BdFile from './BdFile'
import BdRow from './BdRow'
import BdWallet from './BdWallet'


const AddOd = () => {
    const { t } = useTranslation();
    const [btn0,setBtn0]=useState(false);
    const [btn1,setBtn1]=useState(true);
    const [btn2,setBtn2]=useState(false);

    const [upLoad,setUpLoad]=useState(false);
    const [resLoad,setResLoad] = useState(-1);

    const ref0=useRef(null);
    const ref1=useRef(null);
    const ref2=useRef(null);
    const refMes=useRef(null);

    const change = (btn)=> {
        const arr=[setBtn0,setBtn1,setBtn2]
        Object.keys(arr).map((key)=>{
            if(key==btn) {arr[key](true);} else {arr[key](false);}
        });
    }
    return (
        <div className='pr df jcc r_bxof'>
        <div className='add_ch pr'>
            
            <button onClick={()=>change(0)} className={`ani1 btno1 ${btn0===true&&'btno1Act'}`}>{t('upl_of')}</button>
            <button onClick={()=>change(1)} className={`ani1 btno1 ${btn1===true&&'btno1Act'}`}>{t('paste_of')}</button>
            <button onClick={()=>change(2)} className={`ani1 btno1 ${btn2===true&&'btno1Act'}`}>{t('create_of_wal')}</button>
            
        </div>
        <div className='ani1 add_tab pr'>
            <Transition nodeRef={refMes} unmountOnExit={true} in={upLoad} timeout={300} onExited={()=>{setResLoad(-1);}}>
                {state => (  
                    <div ref = {refMes} className={`ani1 pf whF lt0 off_mes tr_Li-${state}`}>
                        {resLoad===-1 ? <Loader /> :<ResAddOff res={resLoad} btn={setUpLoad} />
                        } 
                    </div>
                )}
            </Transition>

            <Transition nodeRef={ref0} unmountOnExit={true} in={btn0} timeout={300} >
                {state => (  
                    <BdFile refa={ref0} setUpLoad={setUpLoad} setResLoad={setResLoad} cl={`tr_Li-${state}`} />
                )}
            </Transition>

            <Transition nodeRef={ref1} unmountOnExit={true} in={btn1} timeout={300} >
                {state => (  
                    <BdRow refa={ref1} setUpLoad={setUpLoad} setResLoad={setResLoad} cl={`tr_Li-${state}`} />
                )}
            </Transition>

            <Transition nodeRef={ref2} unmountOnExit={true} in={btn2} timeout={300} >
                {state => (  
                    <BdWallet refa={ref2} cl={`tr_Li-${state}`} />
                )}
            </Transition>
        </div>
    </div>
    );
};

export default AddOd;