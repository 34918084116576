import React from 'react';
import Img3D from './Img3D';
import {CoinsStInfo} from '../../../API/startinfo';

const CoinCartOff = ({coin}) => {
    //console.log(coin);
    var as_id,price;
    if(coin.coin_id==1) {
        as_id = '';
        price = Number(coin.price) / 1000000000000;
    } else {
        as_id = 'https://www.taildatabase.com/tail/'+CoinsStInfo[coin.coin_id].asset_id;
        price = Number(coin.price) / 1000;
    }
    return (
        <div className='maCo br'>
            <div className='df jcc' style={{borderBottom: '1px #457e68 solid'}}>
            <div className='wv3'><img src={CoinsStInfo[coin.coin_id].img} /></div>
                <div className='fs1' style={{marginLeft: '5px'}}>{CoinsStInfo[coin.coin_id].name}</div>
                {
                as_id!=='' &&
                <a style={{marginLeft: '10px'}} target='_blank' href = {as_id}><i className="fs1 fa fa-external-link" aria-hidden="true"></i></a>
                }
            </div>
            <div style={{marginTop: '8px'}} className="fs2 tac">{price}</div>
        </div>
    )
};

export default CoinCartOff;