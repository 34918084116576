import React from 'react';
import {Route, Routes} from "react-router-dom";
import {pubRoutes} from "../router";
//import Loader from "./UI/Loader/Loader";

const PageRouter = () => {
    return (
            <Routes>
                {pubRoutes.map(route =>
                    <Route
                        element={route.element}
                        path={route.path}
                        key={route.path}
                    />
                )}
            </Routes>
    );
};

export default PageRouter;