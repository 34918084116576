import React,{useState} from 'react';
import { useTranslation } from 'react-i18next';
import Offer from '../../../../API/Offer.js';

const BdFile = ({refa,setUpLoad,setResLoad,cl}) => {
    const { t } = useTranslation();
    const [drAct, setDrAct] = useState(false);

    const handleDrag = function (e) {
        e.preventDefault();
        setDrAct(true);
    };
    const handleLive = function (e) {
        e.preventDefault();
        setDrAct(false);
    };
    const handleDrop = function (e) {
        e.preventDefault();
        if(e.target || e.dataTransfer) {
            setUpLoad(true);
            var files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
            if(files[0]) {
                let reader = new FileReader();	  
                reader.readAsText(files[0]);		  
                reader.onload = function() {
                    const asy = async()=> {
                        try {
                            const list = await Offer.add(reader.result);
                            setResLoad(list.data.status);
                        }
                        catch(e) {
                            console.log('err');
                            setResLoad(0);
                        }
                        finally {
                            document.getElementById("inp_fi1").value = "";
                        }
                    }
                    asy();
                };				  
                reader.onerror = function() {
                    setResLoad(0);
                };
            }
        }
    };
    return (
        <div ref={refa} className={`ani1 pa wd1 cc ${cl}`}>
            <label className={`df jcc ani1 drdr ani1 ${drAct===true&&'drdrA'}`} 
                //onReset={handleReset}
                onDragEnter={handleDrag}
                onDragOver={handleDrag}
                onDragLeave={handleLive}
                onDrop={handleDrop}
                onChange={handleDrop}
            >
                <i className="c1 fs6 fa fa-upload ani1" aria-hidden="true"></i>
                <span className="c1 fs2 ani1">{t('drop_this')}</span>
                <input type="file" style={{"display": "none"}} accept=".offer" id="inp_fi1"/>
            </label>
        </div>

    );
};

export default BdFile;