import React,{useEffect,useState,useRef} from 'react';
import Loader from '../elem/itemnft/loader';
import {Transition} from 'react-transition-group';
import {paths0} from '../../../API/paths0';

const Img3D = (p) => {
    const ref=useRef(null);
    const [url,setUrl]=useState('');
    const [preloader, setPreloader]=useState(true);
    useEffect(()=>{
        const f = async()=> {
        try {
            const res = await fetch(paths0.local+'img/nft/'+p.coll+'/'+p.url)
            .then(response => response.blob())
            .then((image) => {
                setUrl(URL.createObjectURL(image));
            });
          } catch {
            console.error('Failed')
          }
        }
        f();
    },[]);

    return (
        <div className={p.css} id={p.id}>
        {preloader && <Loader />}
        <Transition nodeRef={ref} unmountOnExit={true} in={url!=''} timeout={100} onEnter={() => setPreloader(false)}>
            {state => (
                <img className={`ani1 img3d ${p.cc} pr op1-${state}`} ref={ref} src={url} />
            )}
        </Transition>
        </div>
    );  
};

export default Img3D;