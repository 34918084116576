import React from 'react';
//import v from '../../'
import discord from '../../images/sochial/discord.svg';
import tiktok from '../../images/sochial/tiktok.svg';
import facebook from '../../images/sochial/facebook.svg';
import telegram from '../../images/sochial/telegram.svg';
import twitter from '../../images/sochial/twitter.svg';
import instagram from '../../images/sochial/instagram.svg';
import youtube from '../../images/sochial/youtube.svg';


const Sochial = ({css1,id}) => {

    return (
        <div id={id} className={`df al54 jcc ${css1}`}>
            <a href = 'https://discord.gg/PwD8mVG47A' target = "_blank" className='ani1 so1p'><img className='m1o' src={discord} /></a>
            <a href = 'https://www.tiktok.com/@xivion_crypto' target = "_blank" className='ani1 so1p'><img className='m1o' src={tiktok} /></a>
            <a href = 'https://www.facebook.com/profile.php?id=61560998383820&mibextid=LQQJ4d' target = "_blank" className='ani1 so1p'><img className='m1o' src={facebook} /></a>
            <a href = 'https://t.me/xivion_crypto' target = "_blank" className='ani1 so1p'><img className='m1o' src={telegram} /></a>
            <a href = 'https://twitter.com/xivion_crypto' target = "_blank" className='ani1 so1p'><img className='m1o' src={twitter} /></a>
            <a href = 'https://www.instagram.com/xivion_crypto' target = "_blank" className='ani1 so1p'><img className='m1o' src={instagram} /></a>
            <a href = 'https://www.youtube.com/@xivion_crypto' target = "_blank" className='ani1 so1p'><img className='m1o' src={youtube} /></a>
        </div>
    )
};

export default Sochial;