import React, {useState,useRef} from 'react';
import PlImg from './plimg';
import AboutMnft from "../../modal/AboutMnft";
import WalList from '../../modal/WalList';
import {_generStr,_load,_copy,_accW} from '../../../../func/ActOffer.js';
import { stInfo } from '../../../../API/startinfo.js';

import Nftcartoff from '../../modal/Nftcartoff';
import CoinCartOff from '../../modal/CoinCartOff';

const MultiNFT = (p) => {
    const ref=useRef(null);
    const [walOp,setWalOp] = useState('');

    const [nftShow, setNftShow] = useState('');
    const it = p.item;
console.log(p);
    const idI = 'i_'+_generStr(9);
    return (
        <div className="br dNftNft ap84 df ani1">
        {walOp !== '' && <WalList id={walOp} closex={setWalOp} />}
        <div className='class="br jcc df' style={{width: '70%'}}>
            <div className='iNftNft2 ani1'>
            {
            Object.keys(it.of).map((id)=>(
                it.of[id].id_nft ? (
                    <button key={`nf7o_${_generStr(9)}_$`} style={{margin: '10px 0'}} className="w100 iNftNft ani1" onClick={()=>{setNftShow([it.of[id].id_nft,it.of[id].id_coll]);}}>
                        <PlImg url={it.of[id].prew} coll={stInfo[3][it.of[id].id_coll].coll_id} type={p.type} />
                        <div className="it_r">Royalties {Number(it.of[id].royal)/100}%</div>
                    </button>
                )
                : <CoinCartOff key={`co4o_${_generStr(9)}`} coin={it.of[id]} />
            ))
            }
            </div>
            <div><i className="fs4 fa fa-long-arrow-right" aria-hidden="true"></i></div>
            <div className='iNftNft2 ani1'>
            {
            Object.keys(it.req).map((id)=>(
                it.req[id].id_nft ? (
                    <button key={`nf7r_${_generStr(9)}`} style={{margin: '10px 0'}} className="w100 iNftNft ani1" onClick={()=>{setNftShow([it.req[id].id_nft,it.req[id].id_coll]);}}>
                        <PlImg url={it.req[id].prew} coll={stInfo[3][it.req[id].id_coll].coll_id} type={p.type} />
                        <div className="it_r">Royalties {Number(it.req[id].royal)/100}%</div>
                    </button>
                )
                : <CoinCartOff key={`co4r_${_generStr(9)}`} coin={it.req[id]} />
            ))
            }
            </div>
        </div>

        <div className="DoffAct">
            <input type="text" className='inp_o0' defaultValue={it.o_text} id={idI} />
            <button onClick={()=>_load(idI)} style={{margin: '10px auto'}} className="co6 br fs01 btnDoff ani1"><i className="co6 w100 fs4 fa fa-download" aria-hidden="true"></i>Download<br />Offer</button>
            <button onClick={()=>_copy(idI)} style={{margin: '10px auto'}} className="co6 br fs01 btnDoff ani1"><i className="co6 w100 fs4 fa fa-clipboard" aria-hidden="true"></i>Copy to<br />Clipboard</button>
            <button onClick={()=>setWalOp(idI)} style={{margin: '10px auto'}} className="co6 br fs01 btnDoff ani1"><i className="co6 w100 fs4 fa fa-check" aria-hidden="true"></i>Accept in<br />Wallet</button>
        </div>
        {nftShow !== '' && <AboutMnft nft={nftShow} close={setNftShow} />}
        </div>
    );
};

export default MultiNFT;