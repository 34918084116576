import React,{useState} from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import {AtrStInfo,stInfo} from '../../../../API/startinfo';


const ChoseDivAtr = ({nowColl,nameColl,atr,add_atr,close}) => {
    const { t } = useTranslation();
    const lang = i18n.language;

    const [choAtr,setChoAtr] = useState(atr);
    const d_arr = (obj) => {
        let new_obj = {};
        Object.keys(obj).map((n)=>{
            if(obj[n].length > 0) {
                new_obj[n] = obj[n];
            }
        });
        return new_obj;
    }
    const _atrAdd = (atr,val) => {
        var obj = JSON.parse(JSON.stringify(choAtr));
        if(obj[atr]) {
            let ind = obj[atr].indexOf(val);
            if(ind !== -1) {
                obj[atr].splice(ind,1);
                if(obj[atr].length < 1) {
                    obj = d_arr(obj);
                }
            } else {
                obj[atr].push(val);
            }
        } else {
            obj[atr] = [val];
        }
        setChoAtr(obj);
        //console.log(obj);
        //console.log(jj);
    }
    const _allAtr = (id) => {
        var obj = JSON.parse(JSON.stringify(choAtr));
        obj[id] = [];
        obj = d_arr(obj);
        setChoAtr(obj);
    }
    const atrColl = stInfo[3][nowColl]['atr'];
    //console.log('-------------------------');
    //console.log(choAtr);
    return (

        <div className="whF">
            <div className="upD1 w100 pad15">
            <h2 className="h2 tac fs4">{nameColl}</h2>
                <h3 className="h3">{t('attributes')}</h3>

                <div>
                    {Object.keys(atrColl).map((id)=>(
                        <div key={`aD_${id}`} className='atrSelD br'>
                            <div className='l12 pad15'>{AtrStInfo[id][lang]}</div>
                            <div className='r12 pad15'>
                                {Object.keys(atrColl[id]).map((res)=>(
                                    <button key={`atr_${id}_${res}`} className={`ani1 btnAtr5 br ${
                                        choAtr[id] && choAtr[id].indexOf(atrColl[id][res])!== -1 && 'bA5act'
                                        }`
                                        }
                                        onClick={()=>_atrAdd(id,atrColl[id][res])}
                                    >{atrColl[id][res]}</button>
                                ))}
                                    <button key={'wdkbn6'} className={`ani1 btnAtr5 br ${!choAtr[id] && 'bA5act'}`} 
                                    onClick={()=>_allAtr(id)}>-All-</button>
                            </div>
                        </div>   
                    ))}
                </div>

            </div>
            <div className="downD1 w100 df jcc">
                <button className="ani1 btnser btnC2 fs2 br" onClick={()=>close(false)}>{t('back')}</button>
                <button className="ani1 btnser btnC4 fs2 br" onClick={()=>add_atr(choAtr)}>{t('apply')}</button>
            </div>
        </div>
    );
};

export default ChoseDivAtr;