import React, {useState,useRef} from 'react';
import './btnLm.css';
import {useSerchCSelector,useSerchCUpdate} from '../../../../context/serchC.js';

const BtnLm = (props) => {
    const idn=useRef(null)
    const [isMOpen,setMOpen] = useState(false)
    const updSerch = useSerchCUpdate();

return (

    <div className="wr ani1">
        <button className="btn1 hbtn1 ani1 cn">
            <div className="btnL">
                <div className="n">Multi-offers</div>
            </div>
            <div className="btnR">
                <div className = {`c ani1 ${props.cssc}`}></div>
            </div>
        </button>
    </div>

);
};

export default BtnLm;