import React from 'react';
import imgLogo from './logo.webp';
import NavA from './nava';
import BtnConn from '../ui/buttons/btnconn';
import Langs from '../header/langs';

const Header = () => {
    const lf_1 =(d1) => {
        d1.style = "left:1px;";
        document.getElementById('mrl1').classList.add('mrlA1');
        document.getElementById('mrl2').classList.add('mrlA2');
        document.getElementById('mrl3').classList.add('mrlA3');
        document.getElementById('mrl3').classList.remove('mrlz3');
    }
    const lf_2 =(d1) => {
        d1.style = "";
        document.getElementById('mrl1').classList.remove('mrlA1');
        document.getElementById('mrl2').classList.remove('mrlA2');
        document.getElementById('mrl3').classList.remove('mrlA3');
        document.getElementById('mrl3').classList.add('mrlz3');
    }
    const na_1 =(d1) => {
        d1.style = "right:0;";
        document.getElementById('mrl11').classList.add('mrlA1');
        document.getElementById('mrl22').classList.add('mrlA2');
        document.getElementById('mrl33').classList.add('mrlA3');
    }
    const na_2 =(d1) => {
        d1.style = "";
        document.getElementById('mrl11').classList.remove('mrlA1');
        document.getElementById('mrl22').classList.remove('mrlA2');
        document.getElementById('mrl33').classList.remove('mrlA3');
    }
    const openSubM = ()=> {
        if(document.getElementById('lfBar')) {
        const d1 = document.getElementById('lfBar');
        if(d1.style.left == '') {
            lf_1(d1);
            if(document.getElementById('navj7').style.right != '') {
                na_2(document.getElementById('navj7'));
            }
        } else {lf_2(d1);}
        }
    }
    const openBasM = ()=> {
        const d1 = document.getElementById('navj7');
        if(d1.style.right == '') {
            na_1(d1);
            if(document.getElementById('lfBar')) {
                if(document.getElementById('lfBar').style.left != '') {
                    lf_2(document.getElementById('lfBar'));
                }
            }
        } else {
            na_2(d1);
        }
    }
    return (
        <div className = "header" id='head1'>
            <button className='rm0' onClick={openSubM}>
			    <div id="mrl1" className="mrl ani1"></div>
			    <div id="mrl2" className="mrl mrlz2 ani1"></div>
			    <div id="mrl3" className="mrl mrlz3 ani1"></div>
            </button>
            <div className="df">
                <div className = "logo">
                    <img src={imgLogo}/>
                </div>
                <NavA/>
            </div>
            <div className="df dp0">
            {!window.matchMedia("(max-width: 1050px)").matches && <Langs />}
                <BtnConn/>
            </div>
            <button className='rm1' onClick={openBasM}>
			    <div id="mrl11" className="mrl ani1"></div>
			    <div id="mrl22" className="mrl ani1"></div>
			    <div id="mrl33" className="mrl ani1"></div>
            </button>
        </div>
    );
};
export default Header;
